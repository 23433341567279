import axios from "axios";
import { pathOr } from 'ramda';
import React, { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Switch, Redirect, useLocation } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import ForgotPassword from './pages/forgot-password';
import ForgotUsername from './pages/forgot-username';
import Home from './pages/home';
import SendEmail from './pages/send-email';
import ManagementCommittee from './pages/management-committee';
import Login from './pages/login';
import Members from './pages/members';
import Apartment from './pages/apartment';
import ContactUs from './pages/contact-us';
import Assets from './pages/assets';
import Header from './components/header';
import Nav from './components/nav';
import BorderDesign from './components/border-design'
import Loader from './components/loader'
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import authHeader from './auth/auth-header';
import { makeStyles } from '@material-ui/core/styles';
import { setActiveUser, setUserLogout, selectUserEmail, selectUserName, selectUserToken, isAdmin, isManager } from "./features/userSlice";
import color from './const/colorConst';
import { base_url } from './const/config';

import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      // Purple and green play nicely together.
      main: color.primary
    },
    secondary: {
      // This is green.A700 as hex.
      main: color.secondary
    },
  },
});

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#ffffff',
    backgroundColor: '#111111',
  },
  memberContainer: {
    width: '100%',
    maxWidth: '1300px'
  }
}));

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function App(props) {
  const locationPath = useLocation().pathname;
  
  const classes = useStyles();
  const dispatch = useDispatch();
  const userName = useSelector(selectUserName)
  const userEmail = useSelector(selectUserEmail)
  const isUserAdmin = useSelector(isAdmin)
  const isUserManager = useSelector(isManager)
  const [isLoading, setLoadingState] = useState(true);

  const userToken = useSelector(selectUserToken)
  const query = useQuery();
  const redirectURI = query.get("redirect_url") || '/dashboard';

  const handleSignIn = useCallback((data) => {
    localStorage.setItem('token', data.accessToken);
    dispatch(setActiveUser({
      userName: data.username,
      userEmail: data.email,
      userToken: data.accessToken,
      roleName: pathOr('resident', ['role', 'name'], data)
    }));
  }, [dispatch])


  const handleSignOut = () => {
      dispatch(setUserLogout())
      localStorage.clear();
  }

  useEffect(() => {
    const authHeatherData = authHeader();
    const checkLogin = async () => {
      try {
        const response = await axios.get(`${base_url}/api/verify/session`, { headers: authHeader() });
        handleSignIn(response.data);
        setLoadingState(false);
        return;
      } catch(error){
          setLoadingState(false);
          dispatch(setUserLogout())
          localStorage.clear();
      }
     };
     authHeatherData ? checkLogin() : setLoadingState(false);
  }, [dispatch, handleSignIn, locationPath]);

  const loginProps = {
    userToken,
    userName,
    userEmail,
    handleSignOut
  }
  const getApplication = () => {
    if (isLoading) { return }
    return (
      <ThemeProvider theme={theme}>
        <BorderDesign/>
        <Header {...loginProps}/>
        <Nav userToken={userToken} isAdmin={isUserAdmin}/>
        <div className="container">
          <Switch>
            <Route path="/login">
              <div className="continer-block">
                { !userToken ? <Login handleSignIn={handleSignIn}/> : <Redirect to={redirectURI} />}
              </div>
            </Route>
            <Route path="/managing-committee">
              <div className="continer-block">
                { userToken ? <ManagementCommittee /> : <Redirect to="/login?redirect_url=/managing-committee" />}
              </div>
            </Route>
            <Route path="/send-email">
              <div className="continer-block">
                { userToken ? <SendEmail isAdmin={isUserAdmin} isManager={isUserManager} /> : <Redirect to="/login?redirect_url=/send-email" />}
              </div>
            </Route>
            <Route path="/members/:apartmentId">
              <div className={classes.memberContainer}>
                { userToken ? <Apartment isAdmin={isUserAdmin} isManager={isUserManager} assetType={1}/> : <Redirect to="/login?redirect_url=/members" />}
              </div>
            </Route>
            <Route path="/members">
              <div className={classes.memberContainer}>
                { userToken ? <Members isAdmin={isUserAdmin} isManager={isUserManager}  /> : <Redirect to="/login?redirect_url=/members" />}
              </div>
            </Route>
            <Route path="/circulars">
              <div className="continer-block">
                <Assets isAdmin={isUserAdmin} assetType={1}/>
              </div>
            </Route>
            <Route path="/financials">
              <div className="continer-block">
                { userToken ? <Assets isAdmin={isUserAdmin} assetType={2}/> : <Redirect to="/login?redirect_url=/financials" />}
              </div>
            </Route>
            <Route path="/financials/file">
              <div className="continer-block">
                { userToken ? <Assets isAdmin={isUserAdmin} assetType={2}/> : <Redirect to="/login?redirect_url=/financials" />}
              </div>
            </Route>
            <Route path="/minutes-of-meeting">
              <div className="continer-block">
                { userToken ? <Assets isAdmin={isUserAdmin} assetType={3}/> : <Redirect to="/login?redirect_url=/minutes-of-meeting" />}
              </div>
            </Route>
            <Route path="/hero">
              <div className="continer-block">
                { userToken ? <Assets isAdmin={isUserAdmin} assetType={4}/> : <Redirect to="/login?redirect_url=/hero" />}
              </div>
            </Route>
            <Route path="/contact-us">
              <div className="continer-block">
                <ContactUs />
              </div>
            </Route>
            <Route path="/dashboard">
              <div className="continer-block">
                { userToken ? <Dashboard isAdmin={isUserAdmin} /> : <Redirect to="/login?redirect_url=/dashboard" />}
              </div>
            </Route>
            <Route path="/forgot-password">
              <div className="continer-block">
                { userToken ? <Redirect to="/dashboard" /> : <ForgotPassword />}
              </div>
            </Route>
            <Route path="/forgot-username">
              <div className="continer-block">
                { userToken ? <Redirect to="/dashboard" /> : <ForgotUsername />}
              </div>
            </Route>
            <Route path="/">
              <div className="continer-block-full-width">
                <Home/>
              </div>
            </Route>
          </Switch>
        </div>
        <div className="grey-bg">
          <BorderDesign/>
        </div>
        <footer className="footer"></footer>
      </ThemeProvider>
    )
  }
  return (
    <div className="App">
      <Loader isBlack={true} open={isLoading} />
      {getApplication()}
    </div>
  );
}

export default App;
