import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/container';
import Loader from '../../components/loader';
import { sendInviteEmail } from '../../fetcher';
import Button from '@material-ui/core/Button';

export default function SendEmail() {
  const [isLoading, setLoadingState] = useState(false);

  const sendEmailToMembers = async () => {
    setLoadingState(true);
    try {
      await sendInviteEmail();
      setLoadingState(false);
      return;
    } catch(error){
      setLoadingState(false);
    }
  };

  return(
    <div>
       <Container>
        <h1>Send Email</h1>
        <Loader open={isLoading} />
        <Grid container justify="center" spacing={1}>
          <Button onClick={sendEmailToMembers} fullWidth type="submit" variant="contained" color="secondary">
            Send Email
          </Button>
        </Grid>
      </Container>
    </div>
  );
}