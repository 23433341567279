import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import Container from '../container';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '60vw',
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      width: '90vw'
    }
  },
  backdropBlack: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#ffffff',
    backgroundColor: '#111111',
    opacity: 0.97,
    display: 'flex',
    flexDirection: 'column-reverse',
    overflow: 'hidden'
  }, backdroptDefault: {
      zIndex: theme.zIndex.drawer + 1
  }, closeIconContainer: {
    position: 'absolute',
    right: 0,
    cursor: 'pointer'
  }
  }));
  
function Modal(props) {
    const onClose = () => {
      props.closeModal();
    }
    const classes = useStyles();
    return (
        <Backdrop className={classes.backdropBlack} open={props.open} >
          <Container>
            <div className={classes.root}>
              <div className={classes.closeIconContainer}>
                <CancelIcon onClick={onClose}/>
              </div>
              {props.children}
              </div>
          </Container>
        </Backdrop>
    )
}

Modal.propTypes = {
    open: PropTypes.bool.isRequired,
    closeModal: PropTypes.func.isRequired
};
  
export default Modal;