import React from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeFirstLetterCap } from '../../utils';
import color from '../../const/colorConst';
import { red } from '@material-ui/core/colors';



const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        width: '100%'
      },
      backgroundColor: '#f5f5f5',
      textAlign: 'left'
    },
    linkContainer: {
      '& a': {
        color: color.linkColor,
        fontSize: '15px',
        lineHeight: '20px',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    delete: {
      position:'absolute',
      right: '5px',
      top: '5px',
      [theme.breakpoints.up('sm')]: {
        right: '10px',
        top: '10px',
      },
    }
}));
export default function AssetCard({isAdmin, asset, assetType, setDeleteRequestPayload}) {
  const classes = useStyles();
  const pdfToJpgLisnk = (url, numberOfPages) => {
    const urlArray = url.split('.pdf').join('.jpg');
    const urlArrayPage = urlArray.split('mkresidency/image/upload');
    const generatedURLList = [];
    for(let i=0; i<numberOfPages; i++) {
      generatedURLList.push(urlArrayPage.join(`mkresidency/image/upload/pg_${i+1}`));
    }
    return generatedURLList;
  }
  const getfileLink = (asset) => {
    const isPDF = pathOr('',['storageResponse', 'format'], asset) === 'pdfSupport';
    const numberOfPages = pathOr(1,['storageResponse', 'pages'], asset);
    const fileName = pathOr('',['storageResponse', 'secure_url'], asset);
    if (isPDF) {
      const pageLinks = pdfToJpgLisnk(fileName, numberOfPages);
      return pageLinks.map((pageLink, index) => {return (<div key={pageLink} ><a href={pageLink} rel="noreferrer" target="_blank">{`Click here to view page ${index+1} of document`}</a></div>)});
    } else {
      return (<a href={fileName} rel="noreferrer" target="_blank">Click here to view document/image</a>)
    }
  };
  const getImage = (asset) => {
    const fileName = pathOr('',['storageResponse', 'secure_url'], asset);
    return <img src={fileName} alt={fileName} style={{height: '200px'}} />
  }

  return (
    <Grid item xs={12} sm={12} md={12}> 
        <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                <Typography variant="subtitle1" color="primary" component="span">
                  {asset.title} {asset.sequence ? `(Sequence: ${asset.sequence})`: ''}
                </Typography>
                { asset.sequence && (
                  <Typography variant="subtitle2" color="textPrimary" component="div">
                    <strong>Sequence:</strong> {asset.sequence}
                  </Typography> 
                )}
                <br/>
                <div className={classes.linkContainer}>
                  {assetType === 4 ? getImage(asset)  : getfileLink(asset)}
                </div>
                { asset.description && (
                  <Typography variant="subtitle2" color="textPrimary" component="div">
                   {asset.description}
                  </Typography> 
                )}
                {isAdmin === true && setDeleteRequestPayload && (<div className={classes.delete}><DeleteForeverIcon style={{ color: red[500] }} onClick={()=>setDeleteRequestPayload(asset)}/></div>)}
                <Typography variant="body1" color="textPrimary" component="div" align="right">
                    (Uploaded by {pathOr('',['user', 'title'], asset)} {makeFirstLetterCap(pathOr('',['user', 'firstName'], asset))} {makeFirstLetterCap(pathOr('',['user', 'lastName'], asset))} on {moment(pathOr('',['createdAt'], asset)).format('Do MMMM YYYY, h:mm:ss a')})
                </Typography>
              </CardContent>
            </CardActionArea>
        </Card>
    </Grid> 
  );
}
AssetCard.propTypes = {
  asset: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  setDeleteRequestPayload: PropTypes.func,
  assetType: PropTypes.number.isRequired
};
