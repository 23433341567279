import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/container';
import ForgotPasswordForm from './forgot-password';
import PasswordSetForm from './password-set';


export default function ForgotPassword() {
  const [shouldAskOtp, setShouldAskOtp] = useState(false);
  const [username, setUsername] = useState('');
  
  return(
    <div>
       <Container>
        <h1>Forgot Password</h1>
        <Grid container justify="center" spacing={1}>
          {shouldAskOtp ? (<PasswordSetForm username={username} setShouldAskOtp={setShouldAskOtp}/>)
          : (<ForgotPasswordForm setUsername={setUsername} setShouldAskOtp={setShouldAskOtp}/>)
          } 
        </Grid>
      </Container>
    </div>
  );
}