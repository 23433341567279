import React from 'react';
import { string, bool } from 'prop-types';
import MobileNav from './mobile-nav';
import DesktopNav from './desktop-nav';
import { tabList } from './nav-const';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import './nav.css';

function Nav(props) {
  if (isWidthUp('md', props.width)) {
    return <DesktopNav tabList={tabList} userToken={props.userToken} isAdmin={props.isAdmin}/>;
  }
  return <MobileNav tabList={tabList} userToken={props.userToken} isAdmin={props.isAdmin}/>;
}

Nav.propTypes = {
  userToken: string,
  isAdmin: bool
};
  
export default withWidth()(Nav);