import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotInfo } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Container from '../../components/container';
import Loader from '../../components/loader';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  form: {
      width: 'inherit'
  },
  returnToSignIn: {
    margin: '10px 0',
    textAlign: 'left'
  },
  returnToSignInLink: {
      fontSize: '17px',
      textDecoration: 'none'
  }
}));

const RequestPasswordSchema = yup.object().shape({
  password: yup.string().required('Please enter New Password.')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
  ),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'New Password and Confirm Password must match.').required('Confirm Password is required.'),
  otp: yup.string().required('Please enter OTP.')
});

export default function PasswordSetForm({username, setShouldAskOtp}) {
  const [notification, setNotification] = useState({});
  const [isSuccess, setSuccess] = useState(false);
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(RequestPasswordSchema)
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    data.username = username;
    try {
      const response = await forgotInfo(data, 'password/set');
      const successMessage = pathOr('Password requested successfully.',['data', 'message'], response);
      setNotification({ error: false, text: successMessage});
      setLoadingState(false);
      setSuccess(true);
    } catch(error) {
      const errorMessage = pathOr('Error in requesting Password, Please try again.',['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
      setLoadingState(false);
    }
  };
  const handleClose = () => {
    setNotification({});
  };
  return (
    <Grid key={`update_password`} item xs={12} sm={6} md={6}> 
      <Container inner={true}>
        <Typography variant="h6" color="secondary" component="h2">
         {isSuccess ? 'Your Password is reset successfully!' : 'Enter your new Password and OTP (Please check your email for OTP)'}
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        {!isSuccess && (
          <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                disabled={true}
                required
                fullWidth
                defaultValue={username}
                variant="outlined"
                id="username"
                label="Username"
                error={!!(errors && errors.username)}
                helperText={errors && errors.username &&  errors.username.message}
                {...register('username')}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                required
                fullWidth
                defaultValue=""
                variant="outlined"
                type="password"
                id="password"
                label="New Password"
                error={!!(errors && errors.password)}
                helperText={errors && errors.password &&  errors.password.message}
                autoComplete="off"
                {...register('password')}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                  required
                  fullWidth
                  defaultValue=""
                  variant="outlined"
                  type="password"
                  id="confirmPassword"
                  label="Confirm Password"
                  autoComplete="off"
                  error={!!(errors && errors.confirmPassword)}
                  helperText={errors && errors.confirmPassword &&  errors.confirmPassword.message}
                  {...register('confirmPassword')}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                required
                fullWidth
                defaultValue=""
                variant="outlined"
                id="otp"
                label="Please enter OTP"
                error={!!(errors && errors.otp)}
                helperText={errors && errors.otp &&  errors.otp.message}
                {...register('otp')}
              />
            </div>
            <div>
              <Button fullWidth type="submit" variant="contained" color="secondary">
                  Submit
              </Button>
              <div className={classes.returnToSignIn}>
                <Link className={classes.returnToSignInLink} to="/login" >
                  <Button fullWidth type="submit" variant="outlined" color="primary">
                    Return to Sign In
                  </Button>
                </Link>
              </div>
            </div>
          </form>
        )}
        {isSuccess && (
          <div>
            <div className={classes.returnToSignIn}>
              <Link className={classes.returnToSignInLink} to="/login" >
                <Button fullWidth type="submit" variant="outlined" color="primary">
                  Return to Sign In
                </Button>
              </Link>
            </div>
          </div>
        )}
      </Container>
    </Grid>
  )
};

PasswordSetForm.propTypes = {
  username: PropTypes.string.isRequired,
  setShouldAskOtp: PropTypes.func.isRequired
};
