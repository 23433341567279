import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userName: null,
    userEmail: null,
    userToken: null,
    isAdmin: null
}

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setActiveUser: (state, action) => {
            state.userName = action.payload.userName;
            state.userEmail = action.payload.userEmail;
            state.userToken = action.payload.userToken;
            state.isAdmin = action.payload.roleName === 'admin';
            state.isManager = action.payload.roleName === 'manager';
        },
        setUserLogout: state => {
            state.userName = null;
            state.userEmail = null;
            state.userToken = null;
            state.isAdmin = null;
            state.isManager = null;
        }
    }
});

export const {
    setActiveUser,
    setUserLogout
} = userSlice.actions
export const selectUserName = state => state.user.userName
export const selectUserEmail = state => state.user.userEmail
export const selectUserToken = state => state.user.userToken
export const isAdmin = state => state.user.isAdmin
export const isManager = state => state.user.isManager
export default userSlice.reducer