import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { assetAdd, assetDelete } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import Notification from '../../components/notification';
import Modal from '../../components/modal'
import color from '../../const/colorConst';
import Loader from '../../components/loader';
import { ACTIONS, ASSET_TYPE } from './const'
import AssetCard from './asset-card';

const FILE_SUPPORTED_FORMATS = ['application/pdf', 'image/png', 'image/jpeg', 'image/gif', 'image/tiff', 'application/msword'];
const IMAGE_SUPPORTED_FORMATS = ['image/png', 'image/jpeg', 'image/gif', 'image/tiff'];

const FILE_MAX_SIZE = 8*1024*1024;

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  form: {
      width: 'inherit'
  },
  hide: {
    display: 'none'
  },
  center: {
    textAlign: 'center'
  },
  selectedFile: {
    paddingLeft: '20px',
    color: color.secondary,
    fontSize: '17px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: '10px'
    }
  },
  error: {
    marginTop: '10px',
    color: color.errorColor,
    fontSize: '15px'
  },
  confirm: {
    color: color.errorColor,
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px'
  },
  label: {
    fontSize: '16px',
    marginBottom: '10px',
    color: '#676666'
  }
}));


export default function Asset({closeModal, onSuccess, assetType, action, asset, isAdmin}) {
  let supportedFiles = FILE_SUPPORTED_FORMATS;
  let fileUnSupport = 'Unsupported file type, Supported file types are pdf, png, jpeg, gif, tiff, doc.';
  if (assetType===4) {
    supportedFiles = IMAGE_SUPPORTED_FORMATS
    fileUnSupport = 'Unsupported file type, Supported file types are png, jpeg, gif, tiff.';
  }
  const validation = {
    title: yup.string().required('Please enter Title.').matches(/^[A-Za-z0-9 ]+$/, "Must be only digits or alphabets or space.").min(5, 'Must be at least 5 characters long'),
    uploadFile: yup.mixed().required('Please select File.')
    .test('fileSize', 'File too large', (file) =>  file === null || (file && file[0] && file[0].size <= FILE_MAX_SIZE))
    .test(
      'fileFormat',
      fileUnSupport,
      (file) => file === null || (file && file[0] && supportedFiles.includes(file[0].type))
    )
  }
  if (assetType===4) {
    validation.sequence = yup.string().matches(/^[0-9]+$/, "Must be only integer.");
  }
  const UploadFileSchema = yup.object().shape(validation);
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(UploadFileSchema)
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      data.assetType = assetType;
      const response = action === ACTIONS.ADD ? await assetAdd(data, action) : await assetDelete(data, action);
      const successMessage = pathOr(`${ACTIONS[action]} request for ${ASSET_TYPE[assetType].assetTypeName} has successfully executed.`,['data', 'message'], response);
      if(e) e.target.reset();
      setNotification({ error: false, text: successMessage});
      onSuccess(assetType);
      setLoadingState(false);
      if(action === ACTIONS.DELETE) closeModal();
    } catch(error) {
      setLoadingState(false);
      const errorMessage = pathOr('Something went wrong. Please try again later!', ['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
    }
  };
  const handleClose = () => {
    setNotification({});
  };
  const selectedFile = pathOr('', [0, 'name'], watch('uploadFile'));
  return (
    <Modal open={true} closeModal={closeModal} > 
        <Typography variant="h6" color="secondary" component="h2">
           {ACTIONS[action]} {ASSET_TYPE[assetType].pageHeading}
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        {action === ACTIONS.ADD && (<form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              defaultValue=""
              variant="outlined"
              id="title"
              label={`${ASSET_TYPE[assetType].assetTypeName} Title`}
              error={!!(errors && errors.title)}
              helperText={errors && errors.title &&  errors.title.message}
              {...register('title')}
              autoComplete="off"
            />
          </div>
          {
            assetType===4 && (
              <Fragment>
                <div className={`${classes.fieldContainer}`}>
                  <TextField
                    fullWidth
                    defaultValue=""
                    variant="outlined"
                    id="sequence"
                    label="Sequence Number"
                    error={!!(errors && errors.sequence)}
                    helperText={errors && errors.sequence &&  errors.sequence.message}
                    {...register('sequence')}
                    autoComplete="off"
                  />
              </div>
              <div className={`${classes.fieldContainer}`}>
                <div className={classes.label}>Description</div>
                <TextareaAutosize
                  required
                  fullWidth
                  defaultValue=""
                  variant="outlined"
                  id="description"
                  label="Description"
                  error={!!(errors && errors.description)}
                  helperText={errors && errors.description &&  errors.description.message}
                  {...register('description')}
                  autoComplete="off"
                  style={{width: '100%', height: '100px'}}
                />
              </div>
            </Fragment>
            )
          }
          <div className={`${classes.fieldContainer}`}>
            <label htmlFor="uploadFile">
              <input type="file"  id="uploadFile" name="uploadFile" className={classes.hide} {...register('uploadFile')}/>
              <Fab
                color="secondary"
                size="small"
                component="span"
                aria-label="add"
                variant="extended"
              >
                <AddIcon /> {selectedFile ? 'Select Different File' : 'Select File'}&nbsp;&nbsp;
              </Fab>
              <span className={classes.selectedFile}>{selectedFile}</span>
            </label>
            <div className={classes.error}>{errors && errors.uploadFile &&  errors.uploadFile.message}</div>
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Save
            </Button>
          </div>
        </form>)}
        {action === ACTIONS.DELETE && (
          <div>
            <AssetCard asset={asset} isAdmin={isAdmin} assetType={assetType}/>
            <div className={classes.confirm}>
              Are you sure you want to delete?
            </div>
            <div>
              <Button type="button" variant="contained" color="secondary" onClick={() => {onSubmit({ id: asset.id})}}>
                Confirm Delete
              </Button>
              &nbsp;
              <Button type="button" variant="contained" color="primary" onClick={closeModal}>
                Cancel
              </Button>
          </div>
          </div>
        )}

    </Modal>
  )
};

Asset.propTypes = {
  closeModal: PropTypes.func.isRequired,
  assetType: PropTypes.number.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  asset: PropTypes.object
};
