import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { residentAction, checkResidentUsernameAvailable } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Notification from '../../components/notification';
import Modal from '../../components/modal'
import color from '../../const/colorConst';
import {
  ROLE,
  STATUS,
  LOGIN_STATUS,
  TITLE,
  PROF_TITLE,
  IS_TENANT_OCCUPIED
} from '../../const/enum';
import Loader from '../../components/loader';
import { ACTIONS } from './const'
import UserCard from './user-card';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    margin: '10px 1%',
    display: 'inline-block',
    width:'46%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width:'100%',
      margin: '10px 0'
    }
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  form: {
      width: 'inherit'
  },
  notifier: {
    fontSize: '14px',
    position: 'absolute',
    fontWeight: 'bold',
    right: '20px',
    top: '20px'

  },
  valid: {
    color: color.green
  },
  invalid: {
    color: color.errorColor
  },
  hide: {
    display: 'none'
  },
  center: {
    textAlign: 'center'
  },
  selectedFile: {
    paddingLeft: '20px',
    color: color.secondary,
    fontSize: '17px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: '10px'
    }
  },
  error: {
    marginTop: '10px',
    color: color.errorColor,
    fontSize: '15px'
  },
  confirm: {
    color: color.errorColor,
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px'
  }
}));

const ResidentSchema = yup.object().shape({
  apartmentId: yup.string().required('Please select Apartment.'),
  roleId: yup.string().oneOf(Object.keys(ROLE)).required('Please select Role.'),
  status: yup.string().oneOf(Object.values(STATUS)).required('Please select Status.'),
  loginStatus: yup.string().oneOf(Object.values(LOGIN_STATUS)).required('Please select Login Status.'),
  isTenant: yup.string().oneOf(Object.keys(IS_TENANT_OCCUPIED)).required('Please select if this resident is Tenant.'),
  title: yup.string().oneOf(Object.values(TITLE)).required('Please select Title.'),
  // profTitle: yup.lazy((value) => {
  //   if (value === '') {
  //     return yup.string();
  //   }
  //   yup.string().oneOf(Object.values(PROF_TITLE));
  // }),
  firstName: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid First Name')
    .max(40).required('Please enter First Name.'),
  lastName: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid Last Name')
    .max(40).required('Please enter Last Name.'),
  sequence: yup.string().matches(/^[0-9]+$/, "Sequence must be only integer."),
  membershipNumber: yup.string().matches(/^[A-Za-z0-9 ]*$/, 'Please enter valid Membership Number. No special characters allowed.')
    .max(40).required('Please enter Membership Number.'),
  username: yup.string().required('Please enter New Username.').min(5, 'Minimum length for Username should be 5 characters.')
    .max(20, 'Maximum length for Username should 20 characters.'),
  email: yup.string().email('Invalid Email address').required('Please enter Email.'),
  phone: yup.lazy((value) => {
    if (value === '') {
      return yup.string();
    }
    return yup.string()
    .matches(/^[0-9]*$/, 'Phone number is not valid.')
    .min(10, "Phone number should be 10 digits long.")
    .max(10, "hone number should be 10 digits long.");
  }),
  mobile: yup.lazy((value) => {
    if (value === '') {
      return yup.string();
    }
    return yup.string()
    .matches(/^[0-9]*$/, 'Mobile number is not valid.')
    .min(10, "Mobile number should be 10 digits long.")
    .max(10, "Mobile number should be 10 digits long.");
  })
});

export default function User({closeModal, onSuccess, action, user, isAdmin, apartmentList, setNotificationForParent }) {
  const classes = useStyles();
  const params = useParams();
  const parentApartmentId = params.apartmentId;
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const [isUsernameAvailable, setUsernameAvailable] = useState(-1);
  const defaultValues = {
    apartmentId: parentApartmentId,
    roleId: user.roleId ? user.roleId : 2,
    status: user.status ? user.status : 'Present',
    loginStatus: user.loginStatus ? user.loginStatus : 'Approved',
    title: user.title ? user.title : '',
    profTitle: user.profTitle ? user.profTitle : '',
    firstName: user.firstName ? user.firstName : '',
    lastName: user.lastName ? user.lastName : '',
    membershipNumber: user.membershipNumber ? user.membershipNumber : '',
    email: user.email ? user.email : '',
    username: user.username ? user.username : '',
    phone: user.phone ? user.phone : '',
    mobile: user.mobile ? user.mobile : '',
    startDate: user.startDate ? user.startDate : '',
    endDate: user.endDate ? user.endDate : '',
    isTenant: user.isTenant ? 'Yes': 'No',
    sequence: user.sequence ? user.sequence : 1
  };
  const [formData, setFormData] = useState(defaultValues);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(ResidentSchema),
    mode: 'onBlur'
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      if(action === ACTIONS.EDIT) {
        data.id = user.id
      }
      const response = await residentAction(data, action);
      const successMessage = pathOr(`${ACTIONS[action]} request for user has successfully executed.`,['data', 'message'], response);
      if(action === ACTIONS.ADD && e) {
        e.target.reset();
      } 
      const notificationMessage = { error: false, text: successMessage };
      setNotification(notificationMessage);
      onSuccess(parentApartmentId);
      setLoadingState(false);
      if(action === ACTIONS.DELETE || action === ACTIONS.EDIT) {
        setNotificationForParent(notificationMessage)
        closeModal();
      }
    } catch(error) {
      setLoadingState(false);
      const errorMessage = pathOr('Something went wrong. Please try again later!', ['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
    }
  };

  const checkUsernameAvailability = async (username) => {
    const data = {username};
    if(action === ACTIONS.EDIT) {
      data.id = user.id
    }
    const response = await checkResidentUsernameAvailable(data);
    const successMessage = pathOr(``,['data', 'message'], response);
    setUsernameAvailable(successMessage);
  };

  const handleClose = () => {
    setNotification({});
  };
  const handleChange = (e) => {
    if (e.target.name === 'username') {
      checkUsernameAvailability(e.target.value);
    }
    const newFormData = {...formData};
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
    setValue(e.target.name, e.target.value);
  };

  let userNameAvailableData = {};
  if (isUsernameAvailable ===1) {
    userNameAvailableData = {
      className: classes.valid,
      text: "Available"
    }
  } else if (isUsernameAvailable === 0) {
    userNameAvailableData = {
      className: classes.invalid,
      text: "Not Available"
    }
  } 

  return (
    <Modal open={true} closeModal={closeModal} > 
        <Typography variant="h6" color="secondary" component="h2">
           {ACTIONS[action]} Resident
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        {(action === ACTIONS.ADD || action === ACTIONS.EDIT) && (<form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="apartmentId-label">Apartment Number</InputLabel>
              <Select
                labelId="apartmentId-label"
                id="apartmentId"
                defaultValue={formData.apartmentId}
                onChange={handleChange}
                label="Apartment Number"
                {...register('apartmentId')}
                error={!!(errors && errors.apartmentId)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                  {apartmentList.map(apartment => <MenuItem key={apartment.id} value={apartment.id}>{apartment.apartmentNumber}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Role</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="roleId"
                defaultValue={formData.roleId}
                onChange={handleChange}
                label="Role"
                {...register('roleId')}
                error={!!(errors && errors.roleId)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(ROLE).map((value, key) => <MenuItem key={value} value={key+1}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="status"
                defaultValue={formData.status}
                onChange={handleChange}
                label="Status"
                {...register('status')}
                error={!!(errors && errors.status)}
              >
               <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(STATUS).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Login Status</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="loginStatus"
                defaultValue={formData.loginStatus}
                onChange={handleChange}
                label="Login Status"
                {...register('loginStatus')}
                error={!!(errors && errors.loginStatus)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(LOGIN_STATUS).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Is He/Her Tenant?</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="isTenant"
                defaultValue={formData.isTenant}
                onChange={handleChange}
                label="Is He/Her Tenant?"
                {...register('isTenant')}
                error={!!(errors && errors.isTenant)}
              >
                {Object.values(IS_TENANT_OCCUPIED).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="sequence"
              label="Sequence Number"
              error={!!(errors && errors.sequence)}
              helperText={errors && errors.sequence &&  errors.sequence.message}
              {...register('sequence')}
              defaultValue={formData.sequence}
              onBlur={handleChange}
              autoComplete="off"
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Title</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="title"
                defaultValue={formData.title}
                onChange={handleChange}
                label="Title"
                {...register('title')}
                error={!!(errors && errors.title)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(TITLE).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Professional Title</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="profTitle"
                defaultValue={formData.profTitle}
                onChange={handleChange}
                label="Professional Title"
                {...register('profTitle')}
                error={!!(errors && errors.profTitle)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(PROF_TITLE).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="firstName"
              label='First Name'
              error={!!(errors && errors.firstName)}
              helperText={errors && errors.firstName &&  errors.firstName.message}
              autoComplete="off"
              defaultValue={formData.firstName}
              {...register('firstName')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="lastName"
              label='Last Name'
              error={!!(errors && errors.lastName)}
              helperText={errors && errors.lastName &&  errors.lastName.message}
              autoComplete="off"
              defaultValue={formData.lastName}
              {...register('lastName')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="membershipNumber"
              label='Membership Number'
              error={!!(errors && errors.membershipNumber)}
              helperText={errors && errors.membershipNumber &&  errors.membershipNumber.message}
              autoComplete="off"
              defaultValue={formData.membershipNumber}
              {...register('membershipNumber')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="email"
              label='Email (OTP will be sent to this.)'
              error={!!(errors && errors.email)}
              helperText={errors && errors.email &&  errors.email.message}
              autoComplete="off"
              defaultValue={formData.email}
              {...register('email')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`} style={{position: 'relative'}}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="username"
              label='Username (Needed for login.)'
              error={!!(errors && errors.username)}
              helperText={errors && errors.username &&  errors.username.message}
              autoComplete="off"
              defaultValue={formData.username}
              {...register('username')}
              onBlur={handleChange}
            />
            <div className={`${classes.notifier} ${userNameAvailableData.className}`}>{userNameAvailableData.text}</div>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="phone"
              label='Phone'
              error={!!(errors && errors.phone)}
              helperText={errors && errors.phone &&  errors.phone.message}
              autoComplete="off"
              defaultValue={formData.phone}
              {...register('phone')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="mobile"
              label='Mobile'
              error={!!(errors && errors.mobile)}
              helperText={errors && errors.mobile &&  errors.mobile.message}
              autoComplete="off"
              defaultValue={formData.mobile}
              {...register('mobile')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="startDate"
              label="Owned From"
              type="date"
              className={classes.textField}
              error={!!(errors && errors.startDate)}
              helperText={errors && errors.startDate &&  errors.startDate.message}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              defaultValue={formData.startDate}
              {...register('startDate')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="endDate"
              label="Owned Till"
              type="date"
              error={!!(errors && errors.endDate)}
              helperText={errors && errors.endDate &&  errors.endDate.message}
              InputLabelProps={{
                shrink: true,
              }}
              autoComplete="off"
              defaultValue={formData.endDate}
              {...register('endDate')}
              onBlur={handleChange}
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Save
            </Button>
          </div>
        </form>)}
        {action === ACTIONS.DELETE && (
          <div>
            <UserCard user={user} isAdmin={isAdmin} />
            <div className={classes.confirm}>
              Are you sure you want to delete?
            </div>
            <div>
              <Button type="button" variant="contained" color="secondary" onClick={() => {onSubmit({ id: user.id})}}>
                Confirm Delete
              </Button>
              &nbsp;
              <Button type="button" variant="contained" color="primary" onClick={closeModal}>
                Cancel
              </Button>
          </div>
          </div>
        )}

    </Modal>
  )
};

User.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object
};
