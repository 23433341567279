import moment from 'moment';
export const makeFirstLetterCap = (word) => {
    const wordArray = word.split(" ");
    let formatedWord;
    wordArray.map(word => {
      const capitalizeWord = word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      formatedWord = formatedWord ? `${formatedWord} ${capitalizeWord}` : capitalizeWord;
    });
    return formatedWord;
} 

export const displayDateFormat = (date = '') => {
  return date ? moment(date).format('Do MMMM YYYY, h:mm:ss a') : '';
} 

export const displayOnlyDateFormat = (date = '') => {
  return date ? moment(date).format('Do MMMM YYYY') : '';
} 
