import React from 'react';
import { string, func } from 'prop-types';
import { Fragment } from 'react';
import { Link } from 'react-router-dom';
import WebsiteLogo from '../website-logo'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import { makeStyles } from '@material-ui/core/styles';
import './header.css';

const useStyles = makeStyles((theme) => ({
     root: {
        [theme.breakpoints.down('sm')]: {
            paddingLeft: '10px',
            paddingRight: '10px',
          },
        [theme.breakpoints.up('sm')]: {
            paddingLeft: '20px',
            paddingRight: '20px',
          },
        [theme.breakpoints.up('md')]: {
            paddingLeft: '50px',
            paddingRight: '50px',
        }
     },
     signInNUpText: {
        [theme.breakpoints.down('sm')]: {
           fontSize: '11px',
           paddingTop: 'calc(21px + 2vmin)'
        },
        [theme.breakpoints.up('sm')]: {
            paddingTop: 'calc(10px + 2vmin)'
         }
     }
   }));


function Header({ userToken, userEmail, userName, handleSignOut, width }) {
    const classes = useStyles();
    const getSignInLink = () => {
        return (
            <Fragment>
                <Link to="/login" >Sign In</Link>
                {/* &nbsp;| &nbsp; 
                <Link to="/register" >Register</Link> */}
            </Fragment>
        )
    }
    const getSignOutLink = ({ userEmail, userName }, handleSignOut) => {
        return (
            <Fragment>
                <span>{`${userName}`} &nbsp;| &nbsp;</span>
                <span className="signout" onClick={handleSignOut}>Signout</span>
            </Fragment>
        )
    }
    const getSignInNSignUp = () => {
        // if (isWidthUp('md', width)) {
            return (
                <div className={`signInNUp ${classes.signInNUpText}`}>
                    { userToken ?  getSignOutLink({ userEmail, userName }, handleSignOut) : getSignInLink()  }
                </div>
            )
        // }
    }
  return(
    <header className={`header ${classes.root}`}>
        <WebsiteLogo/>
        {getSignInNSignUp()}
    </header>
  );
}

Header.propTypes = {
    userToken: string,
    userName: string,
    handleSignOut: func.isRequired
};
  
export default withWidth()(Header);