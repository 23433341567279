export  const tabList = [
    {
        label: 'Home',
        to: '/'
    }, {
        label: 'Circular/Notice',
        to: '/circulars'
    }, {
        label: 'Managing Committee',
        to: '/managing-committee'
    }, {
        label: 'Members',
        to: '/members'
    }, {
        label: 'Minutes of Meeting',
        to: '/minutes-of-meeting'
    },  {
        label: 'Financials',
        to: '/financials'
    }, {
        label: 'Payments',
        to: 'https://www.smartcghs.com'
    },
    {
        label: 'Contact Us',
        to: '/contact-us'
    },
    {
        label: 'Dashboard',
        to: '/dashboard',
        login: 'true'
    },
    {
        label: 'Home Images',
        to: '/hero',
        login: 'true',
        isAdmin: true
    }
];

export const menuBGColor = 'default';