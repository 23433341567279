import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}
function getMessage(text) {
  if(text && Array.isArray(text)) {
    return (
      <div>
        {
          text.map((errorObj, key) => (
            <div key={errorObj.name}>{ `${key+1}. ${errorObj.message}` }</div>
          ))
        }
      </div>
    );
  } else if(text) {
    return <div>{text}</div>
  }
}

export default function Notification({message, handleClose}) {
  if(message.text) {
    return(
      <Snackbar open={message.text ? true : false} autoHideDuration={6000} onClose={handleClose}>
          <Alert onClose={handleClose} severity={message.error ? 'error' : 'success'}>
            {getMessage(message.text)}
          </Alert>
      </Snackbar>
    );
  } else {
    return <div></div>
  }
}