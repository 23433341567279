import React, { Fragment } from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import EditIcon from '@material-ui/icons/Edit';
import { red, blue, green } from '@material-ui/core/colors';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import { makeFirstLetterCap, displayDateFormat, displayOnlyDateFormat } from '../../utils';
import color from '../../const/colorConst';
import ManagementCard from "./management-card";


const ROLE = {
  'admin': {
    title: 'Admin Role',
    color: red[500],
    icon: SupervisorAccountIcon
  },
  'resident': {
    title: 'Resident Role',
    color: green[500],
    icon: AccessibilityIcon
  },
  'manager': {
    title: 'Manager Role',
    color: blue[500],
    icon: SupervisedUserCircleIcon
  }
}

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        width: '100%'
      },
      backgroundColor: '#f5f5f5',
      textAlign: 'left'
    },
    linkContainer: {
      '& a': {
        color: color.linkColor,
        fontSize: '15px',
        lineHeight: '20px',
        textDecoration: 'none'
      },
      '& a:hover': {
        textDecoration: 'underline'
      }
    },
    delete: {
      position:'absolute',
      right: '10px',
      top: '120px',
      [theme.breakpoints.up('sm')]: {
        right: '10px',
        top: '10px',
      },
    },
    edit: {
      position:'absolute',
      right: '55px',
      top: '120px',
      [theme.breakpoints.up('sm')]: {
        right: '55px',
        top: '10px',
      }
    },
    managementContainer: {
      padding: '10px',
      marginTop: '10px'
    }
}));
export default function UserCard({isAdmin, user, setDeleteRequestPayload, setEditRequestPayload, setEditMemberPayload, setAddMemberPayload, setDeleteMemberPayload}) {
  const classes = useStyles();
  const getRole = (role) => {
    const roleObj = pathOr({}, [role.name], ROLE);
    return (
      <Fragment>
        <roleObj.icon fontSize="large" style={{ color: roleObj.color }}/>
        <Typography variant="h6" style={{ color: roleObj.color }} component="span">
          {roleObj.title}
        </Typography>
      </Fragment>
    );
  };

  return (
    <Grid item xs={12} sm={12} md={12}> 
        <Card className={classes.root}>
            <CardActionArea>
              <CardContent>
                <Typography variant="h6" color="primary" component="span">
                  {user.title} {makeFirstLetterCap(`${user.firstName} ${pathOr('',['lastName'], user)}`)}
                  { user.membershipNumber && (
                  <Typography variant="subtitle2" color="secondary" component="span">
                    &nbsp;&nbsp;(Membership Number: {user.membershipNumber})
                  </Typography>
                  )}
                </Typography>
                <Typography variant="subtitle2" color="secondary" component="div">
                  {getRole(user.role)} (Login Status: <strong>{user.loginStatus}</strong>)<br/><br/>
                </Typography>
                { user.profTitle && (
                  <Typography variant="subtitle2" color="textPrimary" component="div">
                    <strong>Professional Title:</strong> {user.profTitle}
                  </Typography> 
                )}
                {user.isTenant && (
                  <Typography variant="subtitle2" color="secondary" component="div">
                    <strong>( Tenant )</strong>
                  </Typography>
                )}
                <Typography variant="subtitle2" color="textPrimary" component="div">
                <strong>Username:</strong> {user.username} (<strong>Sequence:</strong> {user.sequence})
                </Typography>
                <Typography variant="subtitle2" color="textPrimary" component="div">
                  <strong>Email:</strong> {user.email}
                  { user.phone && (
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                      &nbsp;| <strong>Phone:</strong> {user.phone}
                    </Typography>
                  )}
                  { user.mobile && (
                    <Typography variant="subtitle2" color="textPrimary" component="span">
                      &nbsp;| <strong>Mobile:</strong> {user.mobile}
                    </Typography>
                  )}
                </Typography>
                { user.startDate && (
                  <Typography variant="subtitle2" color="textPrimary" component="div">
                    <strong>Start Date:</strong> {displayOnlyDateFormat(user.startDate)}
                    { user.endDate && (
                      <Typography variant="subtitle2" color="textPrimary" component="span">
                        &nbsp;| <strong>End Date:</strong> {displayOnlyDateFormat(user.endDate)}
                      </Typography>
                    )}
                  </Typography>
                )}
                {isAdmin === true && setDeleteRequestPayload && (<div className={classes.delete}><DeleteForeverIcon style={{ color: red[500], fontSize: 30 }} onClick={()=>setDeleteRequestPayload(user)}/></div>)}
                {isAdmin === true && setEditRequestPayload && (<div className={classes.edit}><EditIcon style={{ color: green[500], fontSize: 30 }} onClick={()=>setEditRequestPayload(user)}/></div>)}
                <Typography variant="subtitle2" color="textPrimary" component="div" align="right" style={{marginTop:'10px'}}>
                  (Created by {pathOr('',['creator', 'title'], user)} {makeFirstLetterCap(pathOr('',['creator', 'firstName'], user))} {makeFirstLetterCap(pathOr('',['creator', 'lastName'], user))} on {displayDateFormat(pathOr('',['createdAt'], user))})
                </Typography>
              </CardContent>
            </CardActionArea>
            <ManagementCard
              isAdmin={isAdmin}
              user={user}
              setEditMemberPayload={setEditMemberPayload}
              setAddMemberPayload={setAddMemberPayload}
              setDeleteMemberPayload={setDeleteMemberPayload}
            />
        </Card>
    </Grid> 
  );
}
UserCard.propTypes = {
  user: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  setDeleteRequestPayload: PropTypes.func,
  setEditRequestPayload: PropTypes.func,
  setEditMemberPayload: PropTypes.func,
  setAddMemberPayload: PropTypes.func,
  setDeleteMemberPayload: PropTypes.func
};
