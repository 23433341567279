import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotInfo } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Container from '../../components/container';
import Loader from '../../components/loader';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  form: {
      width: 'inherit'
  },
  returnToSignIn: {
    margin: '10px 0',
    textAlign: 'left'
  },
  returnToSignInLink: {
      fontSize: '17px',
      textDecoration: 'none'
  }
}));

const RequestOtpSchema = yup.object().shape({
  firstName: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid First Name')
    .max(40).required('Please enter First Name.'),
  lastName: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid Last Name')
    .max(40).required('Please enter Last Name.'),
  email: yup.string().email('Invalid Email address').required('Please enter Email.')
});

export default function ForgotUsernameForm() {
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(RequestOtpSchema)
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      const response = await forgotInfo(data, 'username');
      const successMessage = pathOr('Username sent on your email.',['data', 'message'], response);
      setNotification({ error: false, text: successMessage});
      setLoadingState(false);
    } catch(error) {
      const errorMessage = pathOr('Error in requesting Username, Please try again.',['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
      setLoadingState(false);
    }
  };
  const handleClose = () => {
    setNotification({});
  };
  return (
    <Grid key={`update_password`} item xs={12} sm={6} md={6}> 
      <Container inner={true}>
        <Typography variant="h6" color="secondary" component="h2">
         Please enter your information
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="firstName"
              label='First Name'
              error={!!(errors && errors.firstName)}
              helperText={errors && errors.firstName &&  errors.firstName.message}
              autoComplete="off"
              {...register('firstName')}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="lastName"
              label='Last Name'
              error={!!(errors && errors.lastName)}
              helperText={errors && errors.lastName &&  errors.lastName.message}
              autoComplete="off"
              {...register('lastName')}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="email"
              label='Email'
              error={!!(errors && errors.email)}
              helperText={errors && errors.email &&  errors.email.message}
              autoComplete="off"
              {...register('email')}
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
                Submit
            </Button>
            <div className={classes.returnToSignIn}>
              <Link className={classes.returnToSignInLink} to="/login" >
                <Button fullWidth type="submit" variant="outlined" color="primary">
                  Return to Sign In
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </Container>
    </Grid>
  )
};
