const h1Color = '#004aad';
const primary = '#004aad';
const primaryText = '#ffffff';
const secondary = '#c1209f';
const errorColor = '#FF0000';
const linkColor = '#33b8ff';
const green = '#228B22';
module.exports = {
    h1Color,
    primary,
    secondary,
    primaryText,
    errorColor,
    linkColor,
    green
}
