import React from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
  managementContainer: {
    padding: '10px',
    marginTop: '10px'
  },
  managementAddContainer: {
    padding: '0 10px 10px 10px',

  }
}));
export default function ManagementCard({isAdmin, user, setEditMemberPayload, setDeleteMemberPayload, setAddMemberPayload}) {
  const classes = useStyles();
  const management = pathOr(null, ['management'], user);
  if (management) {
    return (
      <Card className={classes.managementContainer}>
        <Typography variant="h6" color="secondary" component="div">
          <strong>Management Details</strong>
        </Typography>
        <Typography variant="subtitle2" color="primary" component="div">
          Title: <strong>{management.title}</strong>
        </Typography>
        <Typography variant="subtitle2" component="div">
          Sequence: {management.sequence}
        </Typography>
        {isAdmin && setEditMemberPayload && (
          <Fragment>
            <span >
              <Button type="submit" color="secondary" variant="outlined" onClick={()=>setEditMemberPayload(user)} style={{margin: '10px 0 5px 0' }}>
                Edit Management Details
              </Button>&nbsp;&nbsp;
            </span>
            <span>
              <Button type="submit" color="primary" variant="contained" onClick={()=>setDeleteMemberPayload(user)} style={{margin: '10px 0 5px 0', backgroundColor: '#D11313'}}>
                Remove From Management
              </Button>
            </span>
          </Fragment>
        )}
      </Card>
    )
  } else if (isAdmin && setAddMemberPayload) {
    return (
      <div className={classes.managementAddContainer}>
        <Button fullWidth={true} type="submit" color="primary" variant="outlined" onClick={()=>setAddMemberPayload(user)} style={{margin: '10px 0 5px 0' }}>
          Want to add this resident as a part of Management?
        </Button>
      </div>
    )
  } else {
    return <div/>
  }
}

ManagementCard.propTypes = {
  user: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool,
  setEditMemberPayload: PropTypes.func,
  setAddMemberPayload: PropTypes.func,
  setDeleteMemberPayload: PropTypes.func
};
