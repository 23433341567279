import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import Loader from '../../components/loader';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/container';
import { getAssetList } from '../../fetcher';
import { ACTIONS, ASSET_TYPE } from './const';

import Asset from './asset';
import AssetCard from './asset-card';

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        flexGrow: 1
    },
    buttonContainer: {
      '& a': {
        textDecoration: 'none'
      },
      textAlign: 'right',
      marginBottom: '15px'
    }
}));
export default function Assets({isAdmin, assetType, ...props}) {
    const classes = useStyles();
    const [isLoading, setLoadingState] = useState(true);
    const [action, setAction] = useState(null);
    const [asset, setSelectedAsset] = useState({});
    const [assetList, setAssetList] = useState([]);

    const setDeleteRequestPayload = asset => {
      setSelectedAsset(asset);
      setAction(ACTIONS.DELETE);
    }
    const callAssetList = async (assetType) => {
      try {
        const response = await getAssetList(assetType);
        setAssetList(pathOr([],['data', 'assetList'], response));
        setLoadingState(false);
        return;
      } catch(error){
        setLoadingState(false);
      }
     };
    useEffect(() => {
      callAssetList(assetType);
    }, [assetType]);
  return(
    <div>
      <Container>
        <h1>{ASSET_TYPE[assetType].pageHeading} ({assetList.length})</h1>
        {isAdmin === true && (
          <div className={classes.buttonContainer}>
            <Button type="submit" variant="outlined" color="primary" onClick={()=>setAction(ACTIONS.ADD)}>
              Add {ASSET_TYPE[assetType].pageHeading}
            </Button>
          </div>
        )}
        <Loader open={isLoading} />
        {(action === ACTIONS.ADD || action === ACTIONS.DELETE) && (<Asset isAdmin={isAdmin} action={action} closeModal={setAction} onSuccess={callAssetList} assetType={assetType} asset={asset}/> )}
        <Grid container className={classes.gridRoot} spacing={1}>
          <Grid item xs={12}>
              <Grid container justify="center" spacing={3}>
              {assetList.map((assetRowData, index) => (
                  <AssetCard key={`AssetCard${index}`} asset={assetRowData} isAdmin={isAdmin} setDeleteRequestPayload={setDeleteRequestPayload}  assetType={assetType}/>
              ))}
              </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
Assets.propTypes = {
  assetType: PropTypes.number.isRequired
};
