import React from 'react';
import RoomIcon from '@material-ui/icons/Room';
import EmailIcon from '@material-ui/icons/Email';
import CallIcon from '@material-ui/icons/Call';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/container'

const useStyles = makeStyles((theme) => ({
  icon: {
    top: '4px',
    position: 'relative',
    left: '-6px'
  }
}));

export default function ContactUs() {
  const classes = useStyles();
  return(
    <div>
      <Container>
        <h1>Contact Us</h1>
          <Typography gutterBottom variant="h6" component="h2" color="secondary">
            <RoomIcon className={classes.icon} /> Address:
            <Typography variant="body1" color="primary" component="div">
              The Modest Ketki Co-Op. Group Housing Society Ltd.<br />
              Plot No. 8B, Sector-11,<br />
              Dwarka, New Delhi-110075.<br /><br />
            </Typography>
          </Typography>
         
    
          <Typography gutterBottom variant="h6" component="h2" color="secondary">
            <CallIcon className={classes.icon}/> Phone No:
            <Typography variant="body1" color="primary" component="div">
              +91-11-40502956<br /><br />
            </Typography>
          </Typography>
          
    
          <Typography gutterBottom variant="h6" component="h2" color="secondary">
            <EmailIcon className={classes.icon}/> E-Mail:
            <Typography variant="body1" color="primary" component="div">
              mkresidency11dwarka@gmail.com<br /><br /><br />
            </Typography>
          </Typography>
          
       
          <iframe
            title="location_map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2197.426966181334!2d77.04884268083629!3d28.589006656249282!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d1adc16e083ef%3A0x7a28f468862ed56d!2sMK%20Residency%2C%20Sector%2011%20Dwarka%2C%20Dwarka%2C%20Delhi%2C%20110075%2C%20India!5e0!3m2!1sen!2sus!4v1616234552832!5m2!1sen!2sus"
            width="100%"
            height="450"
            style={{border:0}}
            allowFullScreen=""
            loading="lazy" />
      </Container>
    </div>
  );
}