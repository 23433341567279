import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { updateInfo, checkMyUsernameAvailable } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Container from '../../components/container';
import Loader from '../../components/loader';
import color from '../../const/colorConst';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  form: {
      width: 'inherit'
  },
  notifier: {
    fontSize: '14px',
    position: 'absolute',
    fontWeight: 'bold',
    right: '20px',
    top: '20px'

  },
  valid: {
    color: color.green
  },
  invalid: {
    color: color.errorColor
  },
}));

const UpdateUsernameSchema = yup.object().shape({
  yourPassword: yup.string().required('Please enter your password.'),
  username: yup.string().required('Please enter New Username.').min(5, 'Minimum length for Username should be 5 characters.')
  .max(20, 'Maximum length for Username should 20 characters.')
});

export default function ResetUsername() {
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const [isUsernameAvailable, setUsernameAvailable] = useState(-1);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(UpdateUsernameSchema)
  });

  let userNameAvailableData = {};
  if (isUsernameAvailable ===1) {
    userNameAvailableData = {
      className: classes.valid,
      text: "Available"
    }
  } else if (isUsernameAvailable === 0) {
    userNameAvailableData = {
      className: classes.invalid,
      text: "Not Available"
    }
  } 

  const checkUsernameAvailability = async (username) => {
    const data = {username};
    const response = await checkMyUsernameAvailable(data);
    const successMessage = pathOr(``,['data', 'message'], response);
    setUsernameAvailable(successMessage);
  };

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      const response = await updateInfo(data, 'username');
      const successMessage = pathOr('Username updated successfully.',['data', 'message'], response);
      e.target.reset();
      setNotification({ error: false, text: successMessage});
      setLoadingState(false);
    } catch(error) {
      const errorMessage = pathOr('Error in updating Username, Please try again.',['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
      setLoadingState(false);
    }
  };

  const handleClose = () => {
    setNotification({});
  };

  const handleChange = (e) => {
    if (e.target.name === 'username') {
      checkUsernameAvailability(e.target.value);
    }
  };
  return (
    <Grid key={`update_password`} item xs={12} sm={6} md={6}> 
      <Container inner={true}>
        <Typography variant="h6" color="secondary" component="h2">
          Update Username
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`} style={{position: 'relative'}}>
            <TextField
              required
              fullWidth
              defaultValue=""
              variant="outlined"
              id="username"
              label="New Username"
              error={!!(errors && errors.username)}
              helperText={errors && errors.username &&  errors.username.message}
              {...register('username')}
              autoComplete="off"
              onBlur={handleChange}
            />
            <div className={`${classes.notifier} ${userNameAvailableData.className}`}>{userNameAvailableData.text}</div>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              defaultValue=""
              variant="outlined"
              type="password"
              id="yourPasswordForUsername"
              label="Your Password"
              error={!!(errors && errors.yourPassword)}
              helperText={errors && errors.yourPassword &&  errors.yourPassword.message}
              {...register('yourPassword')}
              autoComplete="off"
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
                Update Username
            </Button>
          </div>
        </form>
      </Container>
    </Grid>
  )
};
