import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import AppBar from '@material-ui/core/AppBar';
import { menuBGColor } from './nav-const'
import DesktopNav from './desktop-nav';
// import InboxIcon from '@material-ui/icons/MoveToInbox';
// import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
 '@global': {
    body: {
        backgroundColor: theme.palette.background.paper,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  fab: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
  snackbar: {
    [theme.breakpoints.down('xs')]: {
        bottom: 90,
    },
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  menuContainer: {
      textAlign: 'left',
      paddingLeft: theme.spacing(2),
  }
}));

export default function MobileNav({tabList, userToken, isAdmin}) {
  const classes = useStyles();
  const [state, setState] = React.useState({
    left: false
  });
  const anchor = "left";

  const handleClick = () => {
    toggleDrawer(anchor, false)
  };


  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <DesktopNav tabList={tabList} orientation={'vertical'} onClickHandle={handleClick} userToken={userToken} isAdmin={isAdmin}/>
    </div>
  );

  return (
    <AppBar position="static" color={menuBGColor}>
        <div className={classes.menuContainer}>
            <React.Fragment key={anchor}>
                <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="menu"
                onClick={toggleDrawer(anchor, true)}
                >
                <MenuIcon />
                </IconButton>
            <Drawer anchor={anchor} open={state.left} onClose={toggleDrawer(anchor, false)}>
                {list(anchor)}
            </Drawer>
            </React.Fragment>
        </div>
    </AppBar>
  );
}

MobileNav.propTypes = {
    tabList: PropTypes.array.isRequired,
    userToken: PropTypes.string,
    isAdmin: PropTypes.bool
};
