export const ROLE = {
  1: 'Admin',
  2: 'Resident',
  3: 'Manager'
};

export const STATUS = {
  Past: 'Past',
  Present: 'Present',
  Future: 'Future'
};

export const LOGIN_STATUS = {
  Pending: 'Pending',
  Approved: 'Approved',
  Declined: 'Declined',
  Expired: 'Expired'
};

export const TITLE = {
  'Mr.': 'Mr.',
  'Mrs.': 'Mrs.',
  'Ms.': 'Ms.',
  'Sh.': 'Sh.'
};

export const PROF_TITLE = {
  'Dr.': 'Dr.',
  'Er.': 'Er.',
  'Adv.': 'Adv.',
  'Col.': 'Col.'
};

export const APARTMENT_CATEGORY = {
  'A': 'A',
  'B': 'B'
};

export const IS_TENANT_OCCUPIED = {
  'No': 'No',
  'Yes': 'Yes'
};