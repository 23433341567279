import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/container';
import ResetPassword from './reset-password';
import ResetUsername from './reset-username';
import UpdateInfo from './update-info';
import Loader from '../../components/loader';
import { getLoginUserInfo } from '../../fetcher';

export default function Dashboard() {
  const [userInfo, setuUserInfo] = useState({});
  const [isLoading, setLoadingState] = useState(true);

  const fetchUserInfo = async () => {
    try {
      const response = await getLoginUserInfo();
      setuUserInfo(pathOr(null,['data', 'user'], response));
      setLoadingState(false);
      return;
    } catch(error){
      setLoadingState(false);
    }
  };
  useEffect(() => {
    fetchUserInfo();
  }, []);

  return(
    <div>
       <Container>
        <h1>Dashboard</h1>
        <Loader open={isLoading} />
        <Grid container justify="center" spacing={1}>
          {isLoading ===false && (<UpdateInfo user={userInfo} fetchUserInfo={fetchUserInfo}/>)}
        </Grid>
        <Grid container justify="center" spacing={1}>
          <ResetPassword />
          <ResetUsername />
        </Grid>
      </Container>
    </div>
  );
}