import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { updateInfo } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Container from '../../components/container';
import Loader from '../../components/loader';
import {
  TITLE,
  PROF_TITLE
} from '../../const/enum';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  form: {
      width: 'inherit'
  }
}));


export default function UpdateInfo({user, fetchUserInfo}) {
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const defaultValues = {
    title: user.title ? user.title : '',
    profTitle: user.profTitle ? user.profTitle : '',
    firstName: user.firstName ? user.firstName : '',
    lastName: user.lastName ? user.lastName : '',
    email: user.email ? user.email : '',
    phone: user.phone ? user.phone : '',
    mobile: user.mobile ? user.mobile : ''
  };

  const MyInfoSchema = yup.object().shape({
    title: yup.string().oneOf(Object.values(TITLE)).required('Please select Title.'),
    firstName: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid First Name')
      .max(40).required('Please enter First Name.'),
    lastName: yup.string().matches(/^[A-Za-z ]*$/, 'Please enter valid Last Name')
      .max(40).required('Please enter Last Name.'),
    email: yup.string().email('Invalid Email address').required('Please enter Email.'),
    phone: yup.lazy((value) => {
      if (value === '') {
        return yup.string();
      }
      return yup.string()
      .matches(/^[0-9]*$/, 'Phone number is not valid.')
      .min(10, "Phone number should be 10 digits long.")
      .max(10, "hone number should be 10 digits long.");
    }),
    mobile: yup.lazy((value) => {
      if (value === '') {
        return yup.string();
      }
      return yup.string()
      .matches(/^[0-9]*$/, 'Mobile number is not valid.')
      .min(10, "Mobile number should be 10 digits long.")
      .max(10, "Mobile number should be 10 digits long.");
    })
  });
  const [formData, setFormData] = useState(defaultValues);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(MyInfoSchema),
    mode: 'onBlur'
  });
  
  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      const response = await updateInfo(data, 'info');
      const successMessage = pathOr('Information updated successfully.',['data', 'message'], response);
      fetchUserInfo();
      setNotification({ error: false, text: successMessage});
      setLoadingState(false);
    } catch(error) {
      const errorMessage = pathOr('Error in updating information, Please try again.',['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
      setLoadingState(false);
    }
  };

  const handleClose = () => {
    setNotification({});
  };

  const handleChange = (e) => {
    const newFormData = {...formData};
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
    setValue(e.target.name, e.target.value);
  };
  return (
    <Grid key={`update_info`} item xs={12} sm={12} md={12}> 
      <Container inner={true}>
        <Typography variant="h6" color="secondary" component="h2">
          Update Info
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Title</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="title"
                defaultValue={formData.title}
                onChange={handleChange}
                label="Title"
                {...register('title')}
                error={!!(errors && errors.title)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(TITLE).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Professional Title</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="profTitle"
                defaultValue={formData.profTitle}
                onChange={handleChange}
                label="Professional Title"
                {...register('profTitle')}
                error={!!(errors && errors.profTitle)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(PROF_TITLE).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="firstName"
                label='First Name'
                error={!!(errors && errors.firstName)}
                helperText={errors && errors.firstName &&  errors.firstName.message}
                autoComplete="off"
                defaultValue={formData.firstName}
                {...register('firstName')}
                onBlur={handleChange}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="lastName"
                label='Last Name'
                error={!!(errors && errors.lastName)}
                helperText={errors && errors.lastName &&  errors.lastName.message}
                autoComplete="off"
                defaultValue={formData.lastName}
                {...register('lastName')}
                onBlur={handleChange}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                required
                fullWidth
                variant="outlined"
                id="email"
                label='Email (OTP will be sent to this.)'
                error={!!(errors && errors.email)}
                helperText={errors && errors.email &&  errors.email.message}
                autoComplete="off"
                defaultValue={formData.email}
                {...register('email')}
                onBlur={handleChange}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="phone"
              label='Phone'
              error={!!(errors && errors.phone)}
              helperText={errors && errors.phone &&  errors.phone.message}
              autoComplete="off"
              defaultValue={formData.phone}
              {...register('phone')}
              onBlur={handleChange}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="mobile"
              label='Mobile'
              error={!!(errors && errors.mobile)}
              helperText={errors && errors.mobile &&  errors.mobile.message}
              autoComplete="off"
              defaultValue={formData.mobile}
              {...register('mobile')}
              onBlur={handleChange}
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
                Update Info
            </Button>
          </div>
        </form>
      </Container>
    </Grid>
  )
};
