import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { forgotInfo } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Container from '../../components/container';
import Loader from '../../components/loader';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  form: {
      width: 'inherit'
  },
  returnToSignIn: {
    margin: '10px 0',
    textAlign: 'left'
  },
  returnToSignInLink: {
      fontSize: '17px',
      textDecoration: 'none'
  }
}));

const RequestOtpSchema = yup.object().shape({
  username: yup.string().required('Please enter Your Username.')
});

export default function ForgotPasswordForm({setUsername, setShouldAskOtp}) {
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(RequestOtpSchema)
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      setUsername(data.username);
      const response = await forgotInfo(data, 'password');
      const successMessage = pathOr('Password requested successfully.',['data', 'message'], response);
      setNotification({ error: false, text: successMessage});
      setLoadingState(false);
      setShouldAskOtp(true);
    } catch(error) {
      const errorMessage = pathOr('Error in requesting Password, Please try again.',['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
      setLoadingState(false);
    }
  };
  const handleClose = () => {
    setNotification({});
  };
  return (
    <Grid key={`update_password`} item xs={12} sm={6} md={6}> 
      <Container inner={true}>
        <Typography variant="h6" color="secondary" component="h2">
         What is your username?
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              defaultValue=""
              variant="outlined"
              id="username"
              label="Username"
              error={!!(errors && errors.username)}
              helperText={errors && errors.username &&  errors.username.message}
              {...register('username')}
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
                Submit
            </Button>
            <div className={classes.returnToSignIn}>
              <Link className={classes.returnToSignInLink} to="/login" >
                <Button fullWidth type="submit" variant="outlined" color="primary">
                  Return to Sign In
                </Button>
              </Link>
            </div>
          </div>
        </form>
      </Container>
    </Grid>
  )
};

ForgotPasswordForm.propTypes = {
  setUsername: PropTypes.func.isRequired,
  setShouldAskOtp: PropTypes.func.isRequired
};


