import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import logo from './logo4.png';

const useStyles = makeStyles((theme) => ({
    logo: {
        'max-width': '100%',
       [theme.breakpoints.down('md')]: {
           width: 'calc(170px + 2vmin)',
         },
         [theme.breakpoints.up('md')]: {
           width: 'calc(250px + 2vmin)',
         }
    }
  }));

export default function WebsiteLogo() {
  const classes = useStyles();
  return(
    <div className="website-logo-container">
        <Link to="/">
          <img className={classes.logo} alt="logo" src={logo} />
        </Link>
    </div>
  );
}
