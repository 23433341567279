import React, { useState } from 'react';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { updateInfo } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Container from '../../components/container';
import Loader from '../../components/loader';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
      margin: '15px 0'
  },
  form: {
      width: 'inherit'
  }
}));

const UpdatePasswordSchema = yup.object().shape({
  yourPassword: yup.string().required('Please enter your Password.'),
  password: yup.string().required('Please enter New Password.')
  .matches(
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
    "Password Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character."
  ),
  confirmPassword: yup.string()
    .oneOf([yup.ref('password'), null], 'New Password and Confirm Password must match.').required('Confirm Password is required.')
});

export default function ResetPassword() {
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const classes = useStyles();
  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(UpdatePasswordSchema)
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      const response = await updateInfo(data, 'password');
        const successMessage = pathOr('Password updated successfully.',['data', 'message'], response);
        e.target.reset();
        setNotification({ error: false, text: successMessage});
        setLoadingState(false);
    } catch(error) {
      const errorMessage = pathOr('Something went wrong. Please try again later!', ['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
      setLoadingState(false);
    }
   
  };
  const handleClose = () => {
    setNotification({});
  };
  return (
    <Grid key={`update_password`} item xs={12} sm={6} md={6}> 
      <Container inner={true}>
        <Typography variant="h6" color="secondary" component="h2">
          Update Password
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        <form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              defaultValue=""
              variant="outlined"
              type="password"
              id="yourPassword"
              label="Your Password"
              error={!!(errors && errors.yourPassword)}
              helperText={errors && errors.yourPassword &&  errors.yourPassword.message}
              {...register('yourPassword')}
              autoComplete="off"
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              defaultValue=""
              variant="outlined"
              type="password"
              id="password"
              label="New Password"
              error={!!(errors && errors.password)}
              helperText={errors && errors.password &&  errors.password.message}
              {...register('password')}
              autoComplete="off"
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
                required
                fullWidth
                defaultValue=""
                variant="outlined"
                type="password"
                id="confirmPassword"
                label="Confirm New Password"
                error={!!(errors && errors.confirmPassword)}
                helperText={errors && errors.confirmPassword &&  errors.confirmPassword.message}
                {...register('confirmPassword')}
                autoComplete="off"
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
                Update Password
            </Button>
          </div>
        </form>
      </Container>
    </Grid>
  )
};
