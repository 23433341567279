import React from 'react';
import PropTypes from 'prop-types';
import { findIndex, propEq } from 'ramda';
import { useHistory } from "react-router-dom";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { menuBGColor } from './nav-const'

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
    key: `simple-tab-${index}`
  };
}

export default function DesktopNav({tabList, orientation="horizontal", onClickHandle, anchor, userToken, isAdmin}) {
  const history = useHistory();
  const tabIndex = findIndex(propEq('to', history.location.pathname))(tabList)
  const [value, setValue] = React.useState(tabIndex>=1 ? tabIndex : 0);

  const handleChange = (event, newValue) => {
    if(tabList[newValue].to.indexOf('http') > -1) {
      window.open(tabList[newValue].to, "_newtab" );    
    } else {
      setValue(newValue);
      history.push(tabList[newValue].to)
    }
    if (onClickHandle) onClickHandle();
  };

  return (
    <div>
      <AppBar position="static" color={menuBGColor}>
        <Tabs orientation={orientation} variant="scrollable" value={value} onChange={handleChange}  textColor="secondary" aria-label="simple tabs example">
            { tabList.map((tab, index) => {
              if(tab.login && !userToken || (tab.isAdmin && !isAdmin)) {
                return '';
              }
              return <Tab label={tab.label} {...a11yProps(index)} />
            })};
        </Tabs>
      </AppBar>
    </div>
  );
}

DesktopNav.propTypes = {
    tabList: PropTypes.array.isRequired,
    orientation: PropTypes.string,
    onClickHandle: PropTypes.func,
    userToken: PropTypes.string,
    isAdmin: PropTypes.bool
};