import axios from "axios";
import authHeader from '../auth/auth-header';
import { base_url } from '../const/config';
import { pathOr, isEmpty, isNil } from 'ramda';
import { ACTIONS } from '../const/actions'

export const updateInfo = async (payload, action) => {
    try {
        const response = await axios.post(
            `${base_url}/api/update/${action}`,
            payload,
            { headers: authHeader()}
        );
        return response;
    } catch(error) {
        throw error;
    }
};
export const forgotInfo = async (payload, action) => {
    try {
        const response = await axios.post(
            `${base_url}/api/forgot/${action}`,
            payload
        );
        return response;
    } catch(error) {
        throw error;
    }
};
export const assetAdd= async (payload, action) => {
    try {
        let formData = new FormData();
        for(let key in payload ) {
            if(key === 'uploadFile') 
                formData.append('file', payload[key][0]); 
            else 
                formData.append(key, payload[key]); 
        }
        const response = await axios.post(
            `${base_url}/api/asset/${action}`,
            formData,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};

export const assetDelete = async (payload, action) => {
    try {
        const response = await axios.post(
            `${base_url}/api/asset/${action}`,
            payload,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};



export const getAssetList = async ( assetType ) => {
    try {
        const response = await axios.get(
            `${base_url}/api/asset/active/${assetType}`,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        checkForTokenExpiredMessage(error);
        throw error;
    }
};


export const getHeroList = async ( assetType ) => {
    try {
        const response = await axios.get(
            `${base_url}/api/hero/active`
        );
        return response;
    } catch(error) {
        throw error;
    }
};




export const checkForTokenExpiredMessage = error => {
    const errorMessage = pathOr('Error in getting data, Please try again.',['response', 'data', 'message'], error);
    const TOKEN_EXPIRED_MESSAGE = 'User is not logged In, Please login first!';
    const TOKEN_EXPIRED_MESSAGE2 = "Please login, it seems your session is expired.";
    if (errorMessage === TOKEN_EXPIRED_MESSAGE || errorMessage === TOKEN_EXPIRED_MESSAGE2) {
        return window.location.reload();
    }
    return false;
}

export const checkForAdminRequireMessage = error => {
    const errorMessage = pathOr('Error in getting data, Please try again.',['response', 'data', 'message'], error);
    const ADMIN_REQUIRED_MESSAGE = 'Require Admin Role!';
    const YOU_DO_NOT_HAVE_PERMISSION_MESSAGE = 'You do not have permission!';
    if (errorMessage === ADMIN_REQUIRED_MESSAGE || errorMessage === YOU_DO_NOT_HAVE_PERMISSION_MESSAGE) {
        return window.location.assign('/');
    }
    return false;
}


export const getApartmentWithResidentById = async ( apartmentId) => {
    try {
        const response = await axios.get(`${base_url}/api/apartment/${apartmentId}`, { headers: authHeader() });
        return response;
    } catch(error) {
        checkForTokenExpiredMessage(error);
        checkForAdminRequireMessage(error);
        throw error;
    }
};

export const getApartmentList = async () => {
    try {
        const response = await axios.get(`${base_url}/api/apartments/list`, { headers: authHeader() });
        return response;
    } catch(error) {
        checkForTokenExpiredMessage(error);
        checkForAdminRequireMessage(error);
        throw error;
    }
};

export const apartmentAction = async (payload, action) => {
    try {
        if ((action === ACTIONS.EDIT) && (isEmpty(payload.id) || isNil(payload.id))) {
            return {data: {message: 'ID is missing for selected apartment!'}};
        }
        const response = await axios.post(
            `${base_url}/api/apartment/${action}`,
            payload,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};


export const residentAction = async (payload, action) => {
    try {
        if ((action === ACTIONS.EDIT || action === ACTIONS.DELETE) && (isEmpty(payload.id) || isNil(payload.id))) {
            return {data: {message: 'ID is missing for selected Resident!'}};
        }
        const response = await axios.post(
            `${base_url}/api/resident/${action}`,
            payload,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};

export const managementAction = async (payload, action) => {
    try {
        if ((action === ACTIONS.EDIT || action === ACTIONS.DELETE) && (isEmpty(payload.id) || isNil(payload.id))) {
            return {data: {message: 'ID is missing for selected member of Management!'}};
        }
        const response = await axios.post(
            `${base_url}/api/management/${action}`,
            payload,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};


export const checkResidentUsernameAvailable = async (payload, action) => {
    try {
        if (action === ACTIONS.EDIT && (isEmpty(payload.id) || isNil(payload.id))) {
            return {data: {message: 'ID is missing for selected Resident!'}};
        }
        const response = await axios.post(
            `${base_url}/api/resident/check/Username`,
            payload,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};

export const checkMyUsernameAvailable = async (payload) => {
    try {
        const response = await axios.post(
            `${base_url}/api/check/Username`,
            payload,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};

export const getLoginUserInfo = async () => {
    try {
        const response = await axios.get(
            `${base_url}/api/user/info`,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};

export const sendInviteEmail = async () => {
    try {
        const response = await axios.get(
            `${base_url}/api/sendInviteEmail/send`,
            { 
                headers: authHeader()
            }
        );
        return response;
    } catch(error) {
        throw error;
    }
};
