import { ACTIONS as ACTION_TYPES } from '../../const/actions';
export const ACTIONS = {...ACTION_TYPES};
export const ASSET_TYPE = {
  1: {
    assetTypeName: 'Circular',
    pageHeading: 'Circular / Notice'
  },
  2: {
    assetTypeName: 'Financial',
    pageHeading: 'Financials'
  },
  3: {
    assetTypeName: 'Minutes of Meeting',
    pageHeading: 'Minutes of Meetings'
  },
  4: {
    assetTypeName: 'Home Images',
    pageHeading: 'Home Images'
  }
}
