import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { pathOr } from 'ramda';
// import Snackbar from '@material-ui/core/Snackbar';
// import MuiAlert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import color from '../../const/colorConst'
import { base_url } from '../../const/config';
import Notification from '../../components/notification'

// function Alert(props) {
//     return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const useStyles = makeStyles((theme) => ({
    rootContainer: {
        [theme.breakpoints.down('sm')]: {
          margin: '10px',
        },
        [theme.breakpoints.up('sm')]: {
            margin: '50px 100px',
        },
        [theme.breakpoints.up('md')]: {
            margin: '100px 150px',
        }
    },
    root: {
      [theme.breakpoints.down('sm')]: {
        padding: '40px 20px',
      },
      [theme.breakpoints.up('sm')]: {
        padding: '40px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '50px',
      }
    },
    loginContainer: {
        width: '100%'
    },
    forgetPassword: {
        margin: '10px 0',
        textAlign: 'left'
    },
    forgetPasswordLink: {
        fontSize: '17px',
        textDecoration: 'none'
    },
    loginWrapper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    fieldContainer: {
        margin: '15px 0'
    },
    h1: {
        textAlign: 'left',
        color: color.h1Color,
        marginTop: 0
    },
    form: {
        width: 'inherit'
    }
  }));

export default function Login({ handleSignIn }) {
    const classes = useStyles();
    const [username, setUserName] = useState();
    const [password, setPassword] = useState();
    const [error, setError] = useState(null);

    const handleSubmit = async e => {
        e.preventDefault();
        try {
            const response = await axios.post(`${base_url}/api/auth/signin`, {
                username: username,
                password: password
            });
            handleSignIn(response.data);
        } catch(error) {
            const errorMessage = pathOr('Error in login, Please try again.',['response', 'message'], error);
            setError(errorMessage);
        }
    }
    const handleClose = () => {
        setError(null);
    };
    const message = {
        error: error ? true : false,
        text: error
    }
  return(
    <div className={`${classes.rootContainer}`}>
        <Paper elevation={3}>
            <div className={`${classes.root} ${classes.loginWrapper}`}>
                <div className={`${classes.loginContainer}`}>
                    <h1 className={`${classes.h1}`}>SECURE SIGN IN</h1>
                    <Notification handleClose={handleClose} message={message}/>
                    {/* <Snackbar open={error ? true : false} autoHideDuration={6000} onClose={handleClose}>
                        <Alert onClose={handleClose} severity="error">
                        { error }
                        </Alert>
                    </Snackbar> */}
                    <form onSubmit={handleSubmit} className={`${classes.form}`}>
                        <div className={`${classes.fieldContainer}`}>
                            <TextField
                                required
                                error={!!error}
                                fullWidth
                                id="username"
                                label="username"
                                defaultValue=""
                                helperText=""
                                variant="outlined"
                                onChange={e => setUserName(e.target.value)}
                            />
                            <div className={classes.forgetPassword}>
                                <Link className={classes.forgetPasswordLink} to="/forgot-username" >Forgot Username?</Link>
                            </div>
                        </div>
                        <div className={`${classes.fieldContainer}`}>
                            <TextField
                                required
                                fullWidth
                                type="password"
                                error={!!error}
                                id="password"
                                label="password"
                                defaultValue=""
                                helperText=""
                                variant="outlined"
                                onChange={e => setPassword(e.target.value)}
                            />
                            <div className={classes.forgetPassword}>
                                <Link className={classes.forgetPasswordLink} to="/forgot-password" >Forgot Password?</Link>
                            </div>
                        </div>
                    
                        <div>
                        <Button fullWidth type="submit" variant="contained" color="secondary">
                            Sign In
                        </Button>
                        </div>
                    </form>
                </div>
            </div>
        </Paper>
    </div>
  )
}
Login.propTypes = {
    handleSignIn: PropTypes.func.isRequired
};