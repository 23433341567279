import React, { useState, useEffect } from 'react';
import authHeader from '../../auth/auth-header';
import axios from "axios";
import { pathOr } from 'ramda';
import Loader from '../../components/loader';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { makeFirstLetterCap } from '../../utils';
import Container from '../../components/container'
import { base_url } from '../../const/config';
import { checkForTokenExpiredMessage } from '../../fetcher';

const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        width: '100%'
      },
      backgroundColor: '#f5f5f5'
    },
    media: {
      height: 140,
    },
    gridRoot: {
        flexGrow: 1
    }
}));
export default function ManagementCommittee() {
    const classes = useStyles();
    const [isLoading, setLoadingState] = useState(true);
    const [managingCommittee, setManagingCommitteeState] = useState([]);
    useEffect(() => {
        const getActiveManagementList = async () => {
          try {
            const response = await axios.get(`${base_url}/api/management/active`, { headers: authHeader() });
            setManagingCommitteeState(pathOr([],['data', 'managementCommittee'], response));
            setLoadingState(false);
            return;
          } catch(error){
            checkForTokenExpiredMessage(error);
            setLoadingState(false);
        }
         };
        getActiveManagementList();
    }, []);
  return(
      <div>
        <Container>
          <h1>Managing Committee</h1>
          <Loader open={isLoading} />
          <Grid container className={classes.gridRoot} spacing={1}>
            <Grid item xs={12}>
                <Grid container justify="center" spacing={3}>
                {managingCommittee.map((member, index) => (
                    <Grid key={`mc_${index}`} item xs={12} sm={12} md={12}> 
                        <Card className={classes.root}>
                            <CardActionArea>
                              <CardContent>
                                <Typography gutterBottom variant="h5" component="span">
                                  {pathOr('',['user', 'title'], member)} {makeFirstLetterCap(pathOr('',['user', 'firstName'], member))} {makeFirstLetterCap(pathOr('',['user', 'lastName'], member))}
                                </Typography>
                                <Typography variant="h6" color="secondary" component="span">
                                &nbsp;&nbsp;({member.title})
                                </Typography>
                                <Typography variant="body2" color="textPrimary" component="span">
                                    &nbsp;&nbsp; Flat No. : {pathOr('',['user', 'apartment', 'apartmentNumber'], member)}
                                </Typography>
                              </CardContent>
                            </CardActionArea>
                        </Card>
                    </Grid>
                ))}
                </Grid>
            </Grid>
          </Grid>
        </Container>
      </div>
    
  );
}