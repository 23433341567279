import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { apartmentAction } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Modal from '../../components/modal'
import color from '../../const/colorConst';
import Loader from '../../components/loader';
import { ACTIONS } from './const'
import UserCard from './user-card';
import {
  APARTMENT_CATEGORY,
  IS_TENANT_OCCUPIED
} from '../../const/enum';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    margin: '10px 1%',
    display: 'inline-block',
    width:'46%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width:'100%',
      margin: '10px 0'
    }
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  form: {
      width: 'inherit'
  },
  notifier: {
    fontSize: '14px',
    position: 'absolute',
    fontWeight: 'bold',
    right: '20px',
    top: '20px'

  },
  valid: {
    color: color.green
  },
  invalid: {
    color: color.errorColor
  },
  hide: {
    display: 'none'
  },
  center: {
    textAlign: 'center'
  },
  selectedFile: {
    paddingLeft: '20px',
    color: color.secondary,
    fontSize: '17px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: '10px'
    }
  },
  error: {
    marginTop: '10px',
    color: color.errorColor,
    fontSize: '15px'
  },
  confirm: {
    color: color.errorColor,
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px'
  }
}));

const ApartmentSchema = yup.object().shape({
  apartmentNumber: yup.string().required('Please eneter apartment number.'),
  category: yup.string().oneOf(Object.values(APARTMENT_CATEGORY)).required('Please select category.'),
  isTenantOccupied: yup.string().oneOf(Object.keys(IS_TENANT_OCCUPIED)).required('Please select if this property is Tenant occupied.'),
  ownerAddress: yup
    .string()
    .ensure()
    .when("isTenantOccupied", {
      is: 'Yes',
      then: yup.string().required("Must enter owner's address.")
  }),
  ownerCity: yup
    .string()
    .ensure()
    .when("isTenantOccupied", {
      is: 'Yes',
      then: yup.string().required("Must enter owner's city.")
    }),
  ownerState: yup
    .string()
    .ensure()
    .when("isTenantOccupied", {
      is: 'Yes',
      then: yup.string().required("Must enter owner's state.")
  }),
  ownerPincode: yup
    .string()
    .ensure()
    .when("isTenantOccupied", {
      is: 'Yes',
      then: yup.string()
      .matches(/^[0-9]*$/, "Owner's pincode is not valid.")
      .required("Must enter owner's pincode.")
      .min(6, 'Must be exactly 6 digits')
      .max(6, 'Must be exactly 6 digits')
    })
});

export default function Apartment({closeModal, onSuccess, action, apartment, isAdmin, setNotificationForParent }) {
  const classes = useStyles();
  const params = useParams();
  const apartmentId = params.apartmentId;
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const defaultValues = {
    apartmentNumber: apartment.apartmentNumber ? apartment.apartmentNumber : '',
    category: apartment.category ? apartment.category : 'A',
    isTenantOccupied: apartment.isTenantOccupied ? 'Yes': 'No',
    ownerAddress: apartment.ownerAddress ? apartment.ownerAddress: '',
    ownerCity: apartment.ownerCity ? apartment.ownerCity: '',
    ownerState : apartment.ownerState  ? apartment.ownerState : '',
    ownerPincode : apartment.ownerPincode  ? apartment.ownerPincode : ''
  };
  const [formData, setFormData] = useState(defaultValues);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors }
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(ApartmentSchema),
    mode: 'all'
  });

  const onSubmit = async (data, e) => {
    console.log('I am here');
    console.log(data);
    setNotification({});
    setLoadingState(true);
    try {
      if (action === ACTIONS.EDIT) {
        data.id = apartmentId
      }
      console.log(data);
      console.log('I am here 1');
      const response = await apartmentAction(data, action);
      const successMessage = pathOr(`${ACTIONS[action]} request for resident has successfully executed.`,['data', 'message'], response);
      console.log('I am here 2');
      const notificationMessage = { error: false, text: successMessage };
      setNotification(notificationMessage);
      onSuccess(apartmentId);
      setLoadingState(false);
      setNotificationForParent(notificationMessage)
      closeModal();
    } catch(error) {
      setLoadingState(false);
      const errorMessage = pathOr('Something went wrong. Please try again later!', ['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
    }
  };

  const handleClose = () => {
    setNotification({});
  };
  const handleChange = (e) => {
    console.log('I am in handleChange');
    const newFormData = {...formData};
    console.log('handleChange - ', e.target.name, e.target.value);
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
    setValue(e.target.name, e.target.value);
  };
  
  console.log('render getvalues - ', getValues('isTenantOccupied'));
  console.log('render form data- ', formData.isTenantOccupied);
  console.log('error ', errors);

  return (
    <Modal open={true} closeModal={closeModal} > 
        <Typography variant="h6" color="secondary" component="h2">
          {action === ACTIONS.EDIT && <span>Edit Apartment</span>}
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        {(action === ACTIONS.ADD || action === ACTIONS.EDIT) && (<form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="apartmentNumber"
              label="Apartment Number"
              error={!!(errors && errors.apartmentNumber)}
              helperText={errors && errors.apartmentNumber &&  errors.apartmentNumber.message}
              {...register('apartmentNumber')}
              autoComplete="off"
              onBlur={handleChange}
              defaultValue={formData.apartmentNumber}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Category</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="category"
                defaultValue={formData.category}
                onChange={handleChange}
                label="Apartment Category"
                {...register('category')}
                error={!!(errors && errors.category)}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {Object.values(APARTMENT_CATEGORY).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div className={`${classes.fieldContainer}`}>
            <FormControl variant="outlined" className={classes.formControl}>
              <InputLabel id="demo-simple-select-outlined-label">Is Tenant Occupied?</InputLabel>
              <Select
                labelId="demo-simple-select-outlined-label"
                id="isTenantOccupied"
                defaultValue={defaultValues.isTenantOccupied}
                label="Is Tenant occupied?"
                {...register('isTenantOccupied')}
                onChange={e => handleChange(e)}
                error={!!(errors && errors.isTenantOccupied)}
              >
                {/* <MenuItem value="">
                  <em>None</em>
                </MenuItem> */}
                {Object.values(IS_TENANT_OCCUPIED).map(value => <MenuItem key={value} value={value}>{value}</MenuItem>)}
              </Select>
            </FormControl>
          </div>
          <div style={{display: formData.isTenantOccupied && formData.isTenantOccupied === 'Yes' ? '' : 'none'}}>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                fullWidth
                variant="outlined"
                id="ownerAddress"
                label="Owner's Address"
                error={!!(errors && errors.ownerAddress)}
                helperText={errors && errors.ownerAddress && errors.ownerAddress.message}
                {...register('ownerAddress')}
                autoComplete="off"
                defaultValue={formData.ownerAddress}
                onBlur={handleChange}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                fullWidth
                variant="outlined"
                id="ownerCity"
                label="Owner's City"
                error={!!(errors && errors.ownerCity)}
                helperText={errors && errors.ownerCity && errors.ownerCity.message}
                {...register('ownerCity')}
                autoComplete="off"
                defaultValue={formData.ownerCity}
                onBlur={handleChange}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                fullWidth
                variant="outlined"
                id="ownerState"
                label="Owner's State"
                error={!!(errors && errors.ownerState)}
                helperText={errors && errors.ownerState && errors.ownerState.message}
                {...register('ownerState')}
                autoComplete="off"
                defaultValue={formData.ownerState}
                onBlur={handleChange}
              />
            </div>
            <div className={`${classes.fieldContainer}`}>
              <TextField
                fullWidth
                variant="outlined"
                id="ownerPincode"
                label="Owner's Pincode"
                error={!!(errors && errors.ownerPincode)}
                helperText={errors && errors.ownerPincode && errors.ownerPincode.message}
                {...register('ownerPincode')}
                autoComplete="off"
                defaultValue={formData.ownerPincode}
                onBlur={handleChange}
              />
            </div>
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Save
            </Button>
          </div>
        </form>)}
    </Modal>
  )
};

Apartment.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  apartment: PropTypes.object,
  setNotificationForParent: PropTypes.func.isRequired
};
