import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from "axios";
import { pathOr } from 'ramda';
import Loader from '../../components/loader';
import authHeader from '../../auth/auth-header';
import './member.css';
import { DataGrid, GridToolbar } from '@material-ui/data-grid';
import { makeFirstLetterCap } from '../../utils';
import { base_url } from '../../const/config';
import Container from '../../components/container'
import { checkForTokenExpiredMessage } from '../../fetcher';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { green, yellow } from '@material-ui/core/colors';

export default function Member({isAdmin, isManager}) {
    const [isLoading, setLoadingState] = useState(true);
    const [apartmentList, setApartmentListState] = useState([]);
    useEffect(() => {
        const getActiveMemberList = async () => {
          try {
            const response = await axios.get(`${base_url}/api/apartments/active`, { headers: authHeader() });
            const apartments = pathOr([],['data', 'apartment'], response);
            for (let i = 0; i<apartments.length; i++) {
                let memberNames;
                const residentList = pathOr([], ['users'], apartments[i]);
                residentList.map((user) => {
                    let fullName = user.profTitle ? user.profTitle : user.title;
                    fullName = fullName ? ` ${fullName} ${user.firstName} ${pathOr('',['lastName'], user)}` : `${user.firstName} ${pathOr('',['lastName'], user)}`;
                    memberNames = memberNames ? `${memberNames} & ${fullName}` : `${fullName}`;
                })
                if(isAdmin || isManager) {
                  apartments[i].occupiedBy = apartments[i].isTenantOccupied ? 'Tenant' : 'Owner';
                }
                apartments[i].memberNames = makeFirstLetterCap(memberNames);
                apartments[i].membershipNumber = pathOr('',[i, 'users', 0, 'membershipNumber'], apartments);
            }
            setApartmentListState(apartments);
            setLoadingState(false);
            return;
          } catch(error){
            checkForTokenExpiredMessage(error);
            setLoadingState(false);
          }
         };
         getActiveMemberList();
    }, []);

    const columns = [
        { field: 'apartmentNumber', headerName: 'Flat No.', width: 110},
        {
          field: 'category',
          headerName: 'Category',
          type: 'string',
          width: 110,
        },
        {
            field: 'membershipNumber',
            headerName: 'M.Ship No.',
            type: 'number',
            width: 130,
            // valueGetter: (apartment) => {
            //     const users = apartment.getValue('users');
            //     return users[0].membershipNumber
            // }
        },
        {
            field: 'memberNames',
            headerName: 'Name of Member',
            width: 550
            // valueGetter: (apartment) => {
            //     return apartment.getValue('memberNames');
            // }
        }
    ];

    if (isAdmin || isManager) {
      columns.unshift({
        field: 'id',
        headerName: 'Edit',
        width: 110,
        filterable: false,
        renderCell: (params) => (
          <strong>
            <Link to={`/members/${params.value}`}>
              {isAdmin && <EditIcon id={params.value} style={{ color: green[500] }} />}
              {isManager && <VisibilityIcon id={params.value} style={{ color: yellow[500] }} />}
            </Link>
          </strong>
        )
      });
      columns.push( {
        field: 'occupiedBy',
        headerName: 'Occupied By',
        width: 150
    })
    }

    const componentsObject = isAdmin === true || isManager === true ? { Toolbar: GridToolbar } : {};
    return(
      <div>
        <Container>
            <h1>List of Members</h1>
            <Loader open={isLoading}/>
            <div style={{ height: 800, width: '100%', backgroundColor: '#FFFFFF' }}>
                <DataGrid rows={apartmentList} columns={columns} pageSize={100}  components={componentsObject}/>
            </div>
        </Container>
      </div>
    
  );
}