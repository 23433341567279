import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { pathOr } from 'ramda';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import * as yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { managementAction } from '../../fetcher';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Notification from '../../components/notification';
import Modal from '../../components/modal'
import color from '../../const/colorConst';
import Loader from '../../components/loader';
import { ACTIONS } from './const'
import UserCard from './user-card';

const useStyles = makeStyles((theme) => ({
  fieldContainer: {
    margin: '10px 1%',
    display: 'inline-block',
    width:'46%',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      width:'100%',
      margin: '10px 0'
    }
  },
  formControl: {
    minWidth: 120,
    width: '100%'
  },
  form: {
      width: 'inherit'
  },
  notifier: {
    fontSize: '14px',
    position: 'absolute',
    fontWeight: 'bold',
    right: '20px',
    top: '20px'

  },
  valid: {
    color: color.green
  },
  invalid: {
    color: color.errorColor
  },
  hide: {
    display: 'none'
  },
  center: {
    textAlign: 'center'
  },
  selectedFile: {
    paddingLeft: '20px',
    color: color.secondary,
    fontSize: '17px',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      marginTop: '10px'
    }
  },
  error: {
    marginTop: '10px',
    color: color.errorColor,
    fontSize: '15px'
  },
  confirm: {
    color: color.errorColor,
    fontSize: '16px',
    marginTop: '10px',
    marginBottom: '10px'
  }
}));

const ManagementSchema = yup.object().shape({
  title: yup.string().required('Please select Title.'),
  sequence: yup.string().matches(/^[0-9]+$/, "Must be only integer.")
});

export default function Management({closeModal, onSuccess, action, user, isAdmin, setNotificationForParent }) {
  const classes = useStyles();
  const params = useParams();
  const parentApartmentId = params.apartmentId;
  const [notification, setNotification] = useState({});
  const [isLoading, setLoadingState] = useState(false);
  const management = pathOr({}, ['management'], user);
  const defaultValues = {
    title: management.title ? management.title : '',
    sequence: management.sequence ? management.sequence : '',
    id: management.id ? management.labelId : '',
    ownerId: user.id ? user.id : '',
  };
  const [formData, setFormData] = useState(defaultValues);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    defaultValues: formData,
    resolver: yupResolver(ManagementSchema),
    mode: 'onBlur'
  });

  const onSubmit = async (data, e) => {
    setNotification({});
    setLoadingState(true);
    try {
      if(action === ACTIONS.EDIT) {
        data.id = management.id
      }
      const response = await managementAction(data, action);
      const successMessage = pathOr(`${ACTIONS[action]} request for resident has successfully executed.`,['data', 'message'], response);
      if(action === ACTIONS.ADD && e) {
        e.target.reset();
      } 
      const notificationMessage = { error: false, text: successMessage };
      setNotification(notificationMessage);
      onSuccess(parentApartmentId);
      setLoadingState(false);
      setNotificationForParent(notificationMessage)
      closeModal();
    } catch(error) {
      setLoadingState(false);
      const errorMessage = pathOr('Something went wrong. Please try again later!', ['response', 'data', 'message'], error);
      setNotification({ error: true, text: errorMessage});
    }
  };

  const handleClose = () => {
    setNotification({});
  };
  const handleChange = (e) => {
    const newFormData = {...formData};
    newFormData[e.target.name] = e.target.value;
    setFormData(newFormData);
    setValue(e.target.name, e.target.value);
  };

  return (
    <Modal open={true} closeModal={closeModal} > 
        <Typography variant="h6" color="secondary" component="h2">
          {action === ACTIONS.DELETE && <span>Remove Resident From Managing Committee</span>}
          {action === ACTIONS.ADD && <span>Add Resident to Managing Committee</span>}
          {action === ACTIONS.EDIT && <span>Edit Managing Committee Information For Resident</span>}
        </Typography>
        <Loader open={isLoading} />
        <Notification handleClose={handleClose} message={notification}/>
        {(action === ACTIONS.ADD || action === ACTIONS.EDIT) && (<form onSubmit={handleSubmit(onSubmit)} className={`${classes.form}`}>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              required
              fullWidth
              variant="outlined"
              id="title"
              label="Title"
              error={!!(errors && errors.title)}
              helperText={errors && errors.title &&  errors.title.message}
              {...register('title')}
              autoComplete="off"
              onBlur={handleChange}
              defaultValue={formData.title}
            />
          </div>
          <div className={`${classes.fieldContainer}`}>
            <TextField
              fullWidth
              variant="outlined"
              id="sequence"
              label="Sequence Number"
              error={!!(errors && errors.sequence)}
              helperText={errors && errors.sequence &&  errors.sequence.message}
              {...register('sequence')}
              autoComplete="off"
              onBlur={handleChange}
              defaultValue={formData.sequence}
            />
          </div>
          <div>
            <Button fullWidth type="submit" variant="contained" color="secondary">
              Save
            </Button>
          </div>
        </form>)}
        {action === ACTIONS.DELETE && (
          <div>
            <UserCard user={user} isAdmin={isAdmin} />
            <div className={classes.confirm}>
              Are you sure you want to remove?
            </div>
            <div>
              <Button type="button" variant="contained" color="secondary" onClick={() => {onSubmit({ id: pathOr('', ['management', 'id'], user)})}}>
                Confirm Remove Resident From Managing Committee
              </Button>
              &nbsp;
              <Button type="button" variant="contained" color="primary" onClick={closeModal}>
                Cancel
              </Button>
          </div>
          </div>
        )}

    </Modal>
  )
};

Management.propTypes = {
  closeModal: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  user: PropTypes.object
};
