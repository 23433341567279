import React, { useState, useEffect } from 'react';
import { pathOr } from 'ramda';
import Slider from 'infinite-react-carousel';
import { makeStyles } from '@material-ui/core/styles';
import Loader from '../../components/loader';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { getHeroList } from '../../fetcher';

const useStyles = makeStyles({
    root: {
    },
    media: {
      minHeight: '70vh'
    },
});

export default function Home() {
    const [heroList, setHeroList] = useState([]);
    const [isLoading, setLoadingState] = useState(true);
    const classes = useStyles();
    const settings =  {
        adaptiveHeight: true,
        autoplay: true,
        dots: true,
        wheelScroll: 2,
        autoplaySpeed: 6000
    };
    const fetchHeroData = async () => {
        try {
          const response = await getHeroList();
          setHeroList(pathOr([],['data', 'heroList'], response));
          setLoadingState(false);
          return;
        } catch(error){
          setLoadingState(false);
        }
    };
    useEffect(() => {
        fetchHeroData();
    }, []);

    const getCard = ({title, description, photo}) => {
        return (
            <Card className={classes.root}>
                <CardActionArea>
                    <CardMedia
                        className={classes.media}
                        image={photo}
                        title={title}
                    />
                    <CardContent>
                    <Typography gutterBottom variant="h5" component="h2">
                        {title}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {description}
                    </Typography>
                    </CardContent>
                </CardActionArea>
            </Card>
        )
    }
    return (
        <div>
            <Loader open={isLoading} />
            {heroList.length > 0 && <Slider { ...settings }>
                {heroList.map(hero => {
                    return (
                        <div key={hero.title}>
                            {getCard({title: hero.title, description: hero.description, photo: hero.secureUrl})}
                        </div>
                    )
                })}
            </Slider>}
        </div>
    );
}