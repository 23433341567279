import React, { useState, useEffect } from 'react';
import { pathOr, filter } from 'ramda';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import { green } from '@material-ui/core/colors';
import Loader from '../../components/loader';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '../../components/container';
import Notification from '../../components/notification';
import { getApartmentWithResidentById, getApartmentList } from '../../fetcher';
import { ACTIONS } from './const';
import { STATUS } from '../../const/enum';

import User from './user';
import Management from './management';
import Apartment from './apartment';
import UserCard from './user-card';
import { Link } from 'react-router-dom';
import { Fragment } from 'react';

const useStyles = makeStyles((theme) => ({
    gridRoot: {
        flexGrow: 1
    },
    buttonContainer: {
      '& a': {
        textDecoration: 'none'
      },
      textAlign: 'right',
      marginBottom: '15px'
    },
    listDivider: {
      fontSize: '20px',
      fontWeight: 'bold',
      marginBottom: '10px',
      marginTop: '10px'
    },
    Present: {
      borderBottom: '1px solid green',
      color: 'green',
    },
    Past: {
      borderBottom: '1px solid #ADD8E6',
      color: '#ADD8E6'
    },
    Future: {
      borderBottom: '1px solid #33A7FF',
      color: '#33A7FF'
    },
    edit: {
      position: 'absolute',
      top: '-4px',
      paddingLeft: '5px',
      cursor: 'pointer',
      [theme.breakpoints.up('sm')]: {
      }
    },
    h1Heading: {
      position: 'relative'
    },
    ownerRoot: {
      marginTop: 0,
      [theme.breakpoints.down('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('sm')]: {
        width: '100%'
      },
      [theme.breakpoints.up('md')]: {
        width: '100%'
      },
      backgroundColor: '#f5f5f5',
      textAlign: 'left'
    },
}));

export default function Apartments({isAdmin, ...props}) {
    const classes = useStyles();
    const params = useParams();
    const apartmentId = params.apartmentId;
    const [notification, setNotification] = useState({});
    const [isLoading, setLoadingState] = useState(true);
    const [action, setAction] = useState(null);
    const [managementAction, setManagementAction] = useState(null);
    const [apartmentAction, setApartmentAction] = useState(null);
    const [user, setSelectedUser] = useState({});
    // const [management, setSelectedManagement] = useState({});
    const [apartment, setApartment] = useState({});
    const [apartmentList, setApartmentList] = useState([]);
    const createResidentListByStatus = (residentList) => {
      const presentResident = filter((resident) => resident.status===STATUS.Present && !resident.isTenant, residentList);
      const pastResident = filter((resident) => resident.status===STATUS.Past && !resident.isTenant, residentList);
      const futureResident = filter((resident) => resident.status===STATUS.Future && !resident.isTenant, residentList);
      const presentTenant = filter((resident) => resident.status===STATUS.Present && resident.isTenant, residentList);
      const pastTenant = filter((resident) => resident.status===STATUS.Past && resident.isTenant, residentList);
      const futureTenant = filter((resident) => resident.status===STATUS.Future && resident.isTenant, residentList);
      return {
        presentResident,
        presentTenant,
        futureResident,
        futureTenant,
        pastResident,
        pastTenant
      }
    }

    const handleClose = () => {
      setNotification({});
    };

    const setAddRequestPayload = () => {
      setSelectedUser({});
      setAction(ACTIONS.ADD);
    }

    const setDeleteRequestPayload = user => {
      setSelectedUser(user);
      setAction(ACTIONS.DELETE);
    }

    const setEditRequestPayload = user => {
      setSelectedUser(user);
      setAction(ACTIONS.EDIT);
    }

    const setEditApartmentRequestPayload = user => {
      setApartmentAction(ACTIONS.EDIT);
    }


    const setAddMemberPayload = (user) => {
      setSelectedUser(user);
      setManagementAction(ACTIONS.ADD);
    }

    const setDeleteMemberPayload = (user) => {
      setSelectedUser(user);
      setManagementAction(ACTIONS.DELETE);
    }

    const setEditMemberPayload = (user) => {
      setSelectedUser(user);
      setManagementAction(ACTIONS.EDIT);
    }

    

    const fetchApartmentList = async () => {
      try {
        const response = await getApartmentList();
        setApartmentList(pathOr(null,['data', 'apartment'], response));
        setLoadingState(false);
        return;
      } catch(error){
        setLoadingState(false);
      }
     };
    useEffect(() => {
      fetchApartmentList();
    }, []);
  
    const fetchApartmentData = async (apartmentId) => {
      try {
        const listResponse = await getApartmentList();
        setApartmentList(pathOr(null,['data', 'apartment'], listResponse));
        const response = await getApartmentWithResidentById(apartmentId);
        setApartment(pathOr(null,['data', 'apartment'], response));
        setLoadingState(false);
        return;
      } catch(error){
        setLoadingState(false);
      }
     };
    useEffect(() => {
      fetchApartmentData(apartmentId);
    }, [apartmentId]);
  const residentList = pathOr([], ['users'], apartment);
  const { 
    presentResident,
    presentTenant,
    futureResident,
    futureTenant,
    pastResident,
    pastTenant
  } = createResidentListByStatus(residentList);

  const getResidentListComponent = (residentList, listType, residentType = '') => {
    if(residentList && residentList.length > 0) {
      return (
        <Grid item xs={12}>
          <div className={`${classes.listDivider} ${classes[listType]}`}>{`${listType} ${residentType}`}</div>
          <Grid container justify="center" spacing={3}>
          {residentList.map((residentRowData, index) => (
              <UserCard 
                key={`UserCard${index}`}
                user={residentRowData}
                isAdmin={isAdmin}
                setDeleteRequestPayload={setDeleteRequestPayload}
                setEditRequestPayload={setEditRequestPayload}
                setEditMemberPayload={setEditMemberPayload}
                setAddMemberPayload={setAddMemberPayload}
                setDeleteMemberPayload={setDeleteMemberPayload}
              />
          ))}
          </Grid>
        </Grid>
      )
    }
    return <div />
  }

  const getOwnerAddressComponent = (apartment) => {
    if(apartment && apartment.isTenantOccupied) {
      const { apartmentNumber, ownerAddress, ownerCity, ownerPincode, ownerState } = apartment;
      return (
        <Grid item xs={12}>
          <Grid container justify="center" pacinsg={3}>
            <Card className={classes.ownerRoot}>
                <CardActionArea>
                  <CardContent>
                    <Typography variant="subtitle2" color="secondary" component="div">
                      {apartmentNumber} is Tenant Occupied.
                    </Typography>
                    <Typography variant="h6" color="primary" component="div">
                      <Typography variant="subtitle1" color="primary" component="span">
                        Owner's Residential Address:&nbsp;
                      </Typography>
                      {ownerAddress}, {ownerCity}, {ownerState} - {ownerPincode}
                    </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        </Grid>
      )
    }
    return <div />
  }

  return(
    <div>
      <Container>
        {apartment &&
          (
            <Fragment>
              <h1 className={classes.h1Heading}>
                Apartment Number : {apartment.apartmentNumber} {apartment.category &&<span>(Category: {apartment.category})</span>}
                {isAdmin === true && setEditApartmentRequestPayload && (<span className={classes.edit}><EditIcon style={{ color: green[500], fontSize: 30 }} onClick={()=>setEditApartmentRequestPayload()}/></span>)}
              </h1>
              {isAdmin === true && (
                <div className={classes.buttonContainer}>
                  <Link to="/members">
                    <Button type="submit" variant="outlined" color="primary">
                      &#8592; Members
                    </Button>
                  </Link>
                  &nbsp;
                  <Button type="submit" variant="outlined" color="secondary" onClick={()=>setAddRequestPayload()}>
                    Add Resident
                  </Button>
                </div>
              )}
              <Loader open={isLoading} />
              <Notification handleClose={handleClose} message={notification}/>
              {
                (action === ACTIONS.ADD || action === ACTIONS.DELETE || action === ACTIONS.EDIT) &&
                  (<User 
                    isAdmin={isAdmin}
                    action={action}
                    apartmentId= {apartmentId}
                    apartmentList={apartmentList}
                    closeModal={setAction}
                    onSuccess={fetchApartmentData}
                    user={user}
                    setNotificationForParent={setNotification}/
                  >
                )
              }
              {
                (managementAction === ACTIONS.ADD || managementAction === ACTIONS.DELETE || managementAction === ACTIONS.EDIT) &&
                  (<Management 
                    isAdmin={isAdmin}
                    action={managementAction}
                    closeModal={setManagementAction}
                    onSuccess={fetchApartmentData}
                    user={user}
                    setNotificationForParent={setNotification}
                  />
                )
              }
              {
                (apartmentAction === ACTIONS.EDIT) &&
                  (<Apartment 
                    isAdmin={isAdmin}
                    action={apartmentAction}
                    closeModal={setApartmentAction}
                    onSuccess={fetchApartmentData}
                    setNotificationForParent={setNotification}
                    apartment={apartment}
                  />
                )
              }
              <Grid container className={classes.gridRoot} spacing={1}>
              {apartment.isTenantOccupied && getOwnerAddressComponent(apartment)}
              {getResidentListComponent(presentResident, 'Present')}
              {getResidentListComponent(presentTenant, 'Present', 'Tenant')}
              {getResidentListComponent(futureResident, 'Future')}
              {getResidentListComponent(futureTenant, 'Future', 'Tenant')}
              {getResidentListComponent(pastResident, 'Past')}
              {getResidentListComponent(pastTenant, 'Past', 'Tenant')}
              </Grid>
            </Fragment>
          )
        }
      </Container>
    </div>
  );
}
Apartments.propTypes = {
  isAdmin: PropTypes.bool,
  isManager: PropTypes.bool
};
