import React from 'react';
import Grid from '@material-ui/core/Grid';
import Container from '../../components/container';
import ForgotUsernameForm from './forgot-username';

export default function ForgotPassword() {  
  return(
    <div>
       <Container>
        <h1>Forgot Username</h1>
        <Grid container justify="center" spacing={1}>
          <ForgotUsernameForm/>
        </Grid>
      </Container>
    </div>
  );
}