import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '30px',
    },
    [theme.breakpoints.up('md')]: {
      margin: '50px',
    }
  },
  root: {
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '10px 30px 30px 30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '20px 50px 50px 50px',
    }
  },
  rootInner: {
    [theme.breakpoints.down('sm')]: {
      padding: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      padding: '20px',
    }
  },
  containerInner: {
    [theme.breakpoints.down('sm')]: {
      margin: '10px',
    },
    [theme.breakpoints.up('sm')]: {
      margin: '20px',
    }
  },
  contentContainer: {
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      textAlign: 'left'
    }
  }
}));

export default function Container(props) {
  const classes = useStyles();
  const containerClass = props.inner ? 'containerInner' : 'container';
  const rootClass = props.inner ? 'rootInner' : 'root';
  return(
    <div className={`${classes[containerClass]}`}>
      <Paper elevation={3}>
        <div className={`${classes[rootClass]} ${classes.contentContainer}`}>
          {props.children}
        </div>
      </Paper>
    </div>
  );
}