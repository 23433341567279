import React from 'react';
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
import './border-design.css';

function BorderDesign(props) {
    // if (isWidthUp('md', props.width)) {
    //   return <DesktopNav tabList={tabList}/>;
    // }
  
    // return <MobileNav tabList={tabList}/>;
    return(
        <div id="border-design">
            <div id="border-design-left"></div>
            <div id="border-design-right"></div>
        </div>
    )
}
  
export default withWidth()(BorderDesign);