import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

const useStyles = makeStyles((theme) => ({
    backdropBlack: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#ffffff',
      backgroundColor: '#111111',
    }, backdroptDefault: {
        zIndex: theme.zIndex.drawer + 1
      },
  }));
  
function Loader(props) {
    const classes = useStyles();
    
    return (
        <Backdrop className={props.isBlack ? classes.backdropBlack : classes.backdroptDefault} open={props.open} >
            <CircularProgress color="inherit" />
        </Backdrop>
    )
}

Loader.propTypes = {
    open: PropTypes.bool.isRequired
};
  
export default Loader;